<template>
  <div class="d-flex">
    <v-radio-group
      v-model="inputVal"
      :readonly="readOnlyInputs"
      hide-details
      :class="`${importedDataStyling}`"
    >
      <v-radio
        v-for="(optionItem, optionKey) in optionList"
        :key="optionKey"
        :label="`${optionItem}`"
        :value="optionKey"
        hide-details
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  props: [
    "value",
    "options",
    "optionScores",
    "readOnlyInputs",
    "importedDataStyling",
  ],
  data: function() {
    return {
      optionList: [],
      default: false,
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value ? this.value.toString() : null;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    optionScores(newVal, oldVal) {
      if (newVal) {
        this.optimizeOptionList();
      }
    },
  },
  methods: {
    optimizeOptionList() {
      let newOptions = {};
      this.optionList = JSON.parse(JSON.stringify(this.options.values));
      for (const [optKey, optItem] of Object.entries(this.optionList)) {
        newOptions[optKey] = optItem;
        let optionValue = this.getScoreOfOption(optKey);
        if (!isNaN(optionValue) && optionValue) {
          newOptions[optKey] = `${optItem} - ${optionValue} pts`;
        }
      }
      this.optionList = newOptions;
    },
    getScoreOfOption(optionKey) {
      let value = null;
      if (this.optionScores && this.optionScores?.PSValues) {
        if (Object.keys(this.optionScores?.PSValues).includes(optionKey)) {
          value = this.optionScores.PSValues[optionKey];
        }
      }
      return value;
    },
  },
  mounted() {
    this.optionList = JSON.parse(JSON.stringify(this.options.values));
    this.default = this.options.default;
    this.optimizeOptionList();
  },
};
</script>

<style></style>
