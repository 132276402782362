<template>
  <div>
    <v-row
      v-for="(proOrMat, proOrMatKey) in component.proOrMatList"
      :key="`component_promat_${proOrMatKey}`"
    >
      <v-col cols="12">
        <v-row v-show="proOrMat.isVisible || proOrMat.isVisibleAction">
          <v-col cols="12" xs="12" sm="6">
            <h5 class="text-right" v-if="proOrMat">
              {{ proOrMat.name }}
            </h5>
          </v-col>
          <v-col cols="12" xs="12" sm="6" v-if="proOrMat">
            <img
              v-if="
                getProOrMatImageComponent(component.assignType, proOrMat, true)
              "
              style="border: 1px solid #aaa; max-width: 80%; cursor: pointer;"
              :src="
                getProOrMatImageComponent(component.assignType, proOrMat, true)
              "
              @click="
                onItemShowImageDialog(component.assignType, proOrMat, false)
              "
              alt=""
            />
            <b-button
              class="ml-2 btn_color_white"
              size="sm"
              v-if="proOrMat.alertList.length > 0"
              :variant="proOrMat.isReadProductAlert ? `success` : `danger`"
              @click="
                onClickProductAlert(
                  proOrMat.alertList,
                  surveyListItem,
                  component,
                  proOrMat
                )
              "
            >
              <b-icon icon="alarm-fill"></b-icon>
            </b-button>
          </v-col>
        </v-row>
        <v-row v-show="proOrMat.isVisible || proOrMat.isVisibleAction">
          <template v-for="questionId in proOrMat.sortedKeysQuestion">
            <template
              v-for="question in [
                getQuestionItem(proOrMat.questions, questionId),
              ]"
            >
              <v-col
                v-if="question"
                cols="12"
                xs="12"
                :sm="
                  ![QUESTION_TYPES.LONGTEXT].includes(question.questionType)
                    ? 6
                    : 12
                "
                :md="
                  ![QUESTION_TYPES.LONGTEXT].includes(question.questionType)
                    ? 3
                    : 12
                "
                :key="
                  `component_proOrMat_${surveyListKey}_${comKey}_${proOrMatKey}_question_${question.id}`
                "
                v-show="question.isVisible && question.isCondVisible"
              >
                <SurveyQuestion
                  :detectedSurveyChainIdForPuncDetails="
                    detectedSurveyChainIdForPuncDetails
                  "
                  :worksession_survey_response_approval="
                    question.worksession_survey_response_approval
                  "
                  :optionScores="question.optionScores"
                  :initialValue="question.value"
                  :currentScore="question.score"
                  :maximumScore="question.maximumScore"
                  :question="question"
                  @onClickCompQueImage="(image) => onClickCompQueImage(image)"
                  @onClickCorrectionAlert="
                    (worksession_survey_response_approval) =>
                      onClickCorrectionAlert(
                        worksession_survey_response_approval
                      )
                  "
                  @input="
                    (result, typeStr) =>
                      persistQuestionValue(
                        result,
                        typeStr,
                        surveyListItem.id,
                        component.id,
                        proOrMat?.id ? proOrMat.id : null,
                        question.id,
                        component.assignType,
                        question,
                        `survey_question_ref_${proOrMatKey}_${question.id}`,
                        question.isCondVisible
                      )
                  "
                  :ref="`survey_question_ref_${proOrMatKey}_${question.id}`"
                />
              </v-col>
            </template>
          </template>
        </v-row>
        <v-row v-if="component.isAction">
          <v-col cols="12" xs="12" sm="3" class="text-left d-flex align-center">
            <b-button
              class="mr-1"
              variant="success"
              size="sm"
              style="color: #fff !important;"
              v-show="proOrMat.isVisible || proOrMat.isVisibleAction"
              @click="
                onClickUpsertActions(
                  surveyListItem,
                  component,
                  proOrMat,
                  null,
                  null
                )
              "
            >
              <b-icon icon="plus"></b-icon>
              {{ $t("Add Action") }}
            </b-button>
          </v-col>
          <v-col cols="12" xs="12" sm="9" class="text-left">
            <v-chip
              :class="
                `m-2 added_action_chip ${
                  actionItem.actionType === 'PROMOLOCAL'
                    ? 'chip_promo_local'
                    : ''
                }`
              "
              v-for="(actionItem, actionKey) in proOrMat?.actions"
              v-show="actionItem.isVisible"
              v-bind:key="`add_action_item_${actionKey}`"
              close
              @click:close="
                onClickRemoveAction(
                  surveyListItem,
                  component,
                  proOrMat,
                  actionKey
                )
              "
            >
              <div
                class="px-2 py-2"
                style="cursor: pointer;"
                @click="
                  onClickUpsertActions(
                    surveyListItem,
                    component,
                    proOrMat,
                    actionItem,
                    actionKey
                  )
                "
              >
                {{ actionItem?.actionLabel }}
                <v-divider class="my-1"></v-divider>
                <p style="max-width: 200px; text-wrap: wrap">
                  {{ actionItem.comments }}
                </p>
              </div>
            </v-chip>
          </v-col>
        </v-row>
        <v-row
          v-show="proOrMat.isVisible || proOrMat.isVisibleAction"
          v-if="component.assignType === 'PRODUCT'"
        >
          <v-col cols="12" md="12">
            <promosAnswerTable
              v-if="currentPromosForSelectedBrand.length > 0"
              :currentPromosForSelectedBrand="
                getCurrentPromosForSelectedBrandProOrMat(
                  currentPromosForSelectedBrand,
                  component,
                  proOrMat
                )
              "
              :promosTypeList="promosTypeList"
              :placeTypeList="placeTypeList"
              :folletoTypeList="folletoTypeList"
              :component="component"
              :proOrMat="proOrMat"
              @changeAnswer="
                (changeKey, record) => persistPromosAnswer(changeKey, record)
              "
            />
          </v-col>
        </v-row>
        <v-divider
          v-if="
            Object.keys(component.proOrMatList).pop() !== proOrMatKey &&
              (proOrMat.isVisible || proOrMat.isVisibleAction)
          "
          class="survey_pro_divider"
        ></v-divider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SurveyQuestion from "./question";
import promosAnswerTable from "./promosAnswerTable.vue";

export default {
  name: "surveyListView",

  props: [
    "QUESTION_TYPES",
    "surveyListItem",
    "component",
    "surveyListKey",
    "comKey",
    "currentPromosForSelectedBrand",
    "getQuestionItem",
    "getProOrMatImageComponent",
    "getCurrentPromosForSelectedBrandProOrMat",
    "detectedSurveyChainIdForPuncDetails",
    "onItemShowImageDialog",
    "onClickProductAlert",
    "onClickCompQueImage",
    "onClickCorrectionAlert",
    "persistQuestionValue",
    "onClickUpsertActions",
    "onClickRemoveAction",
    "persistPromosAnswer",
    "promosTypeList",
    "placeTypeList",
    "folletoTypeList",
  ],

  components: {
    SurveyQuestion,
    promosAnswerTable,
  },

  watch: {
    component(newval, oldval) {
      console.log("newval, oldval - ", newval, oldval);
    },
  },
};
</script>
