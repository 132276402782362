<template>
  <v-textarea
    :class="`${importedDataStyling}`"
    v-model="controlValue"
    @change="onChangeInput"
    :readonly="readOnlyInputs"
  ></v-textarea>
</template>

<script>
export default {
  props: ["value", "readOnlyInputs", "importedDataStyling"],
  data: function() {
    return {
      alphanumericRules: [
        (v) =>
          !v || (isNaN(this.value) && /\d/.test(this.value)) || "Inválida !",
      ],
      controlValue: this.value,
    };
  },
  watch: {
    value(newVal, oldVal) {
      this.controlValue = newVal;
    },
  },
  methods: {
    onChangeInput() {
      this.$emit("input", this.controlValue);
    },
  },
};
</script>

<style></style>
