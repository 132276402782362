var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"w-full d-flex justify-content-center pb-2 position-relative",staticStyle:{"min-height":"50px"}},[(
        _vm.question &&
          _vm.attachments[0] &&
          _vm.attachments[0]?.worksessionSurveyResponsePhoto &&
          !_vm.question?.isFromLastResponse &&
          _vm.isTodayVisit &&
          this.ISFORCURRENTUSER
      )?_c('v-icon',{staticClass:"modal_close_button",attrs:{"x-large":""},on:{"click":_vm.deleteImageItem}},[_vm._v(" mdi-close-circle-outline ")]):_vm._e(),(_vm.question && _vm.isTodayVisit && this.ISFORCURRENTUSER)?_c('v-btn',{staticClass:"btn_add_attachments",attrs:{"color":"primary","dark":""},on:{"click":_vm.onClickAddAttachments}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" añadir foto ")],1):_vm._e()],1),_c('div',{staticClass:"w-full d-flex justify-content-start pb-2 position-relative",staticStyle:{"min-height":"40px"}},[_c('h6',[_vm._v(" "+_vm._s(_vm.currentWorksessionDate)+" "+_vm._s(this.userForCurrentSliderItem && !this.ISFORCURRENTUSER ? ` / ${this.userForCurrentSliderItem?.name} ${this.userForCurrentSliderItem?.surname}` : "")+" ")]),(
        this.worksessionSurveyPhotosPortal &&
          this.worksessionSurveyPhotosPortal?.isWarning !== null
      )?_c('b-icon',{staticClass:"mx-1 badge_warning",attrs:{"icon":"exclamation-triangle-fill","font-scale":"2","variant":this.worksessionSurveyPhotosPortal?.isWarning == true
          ? `danger`
          : `success`}}):_vm._e()],1),(_vm.slides.length > 0)?_c('div',{staticClass:"image-slider"},[_c('div',{staticClass:"slides",style:({ transform: `translateX(-${_vm.currentIndex * 100}%)` })},_vm._l((_vm.slides),function(slide,index){return _c('div',{key:index,staticClass:"slide"},[_c('img',{attrs:{"src":slide,"alt":"Slide"}})])}),0),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex > 0),expression:"currentIndex > 0"}],staticClass:"btn_slider btn_prev",on:{"click":function($event){$event.preventDefault();return _vm.prevSlide.apply(null, arguments)}}},[_c('b-icon',{staticClass:"mx-1",attrs:{"variant":"success","icon":"arrow-left-circle","font-scale":"3"}})],1),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex < _vm.slides.length - 1),expression:"currentIndex < slides.length - 1"}],staticClass:"btn_slider btn_next",on:{"click":function($event){$event.preventDefault();return _vm.nextSlide.apply(null, arguments)}}},[_c('b-icon',{staticClass:"mx-1",attrs:{"variant":"success","icon":"arrow-right-circle","font-scale":"3"}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }