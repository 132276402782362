<template>
  <h4 v-if="component.name && !component.hidelabel" class="title_component">
    <span>{{ component.name }}</span>
    <b-button
      class="my-1 ml-3"
      variant="success"
      size="sm"
      v-if="component?.surveyComponentPhoto"
      @click="onClickCompQueImage(component?.surveyComponentPhoto)"
    >
      <b-icon icon="card-image"></b-icon>
    </b-button>
  </h4>
</template>

<script>
export default {
  name: "surveyComponentTitle",
  props: ["component", "onClickCompQueImage"],
};
</script>
