<template>
  <div>
    <v-text-field
      v-if="!readOnlyInputs"
      type="text"
      v-model="controlValue"
      @change="onChangeInput"
      :readonly="readOnlyInputs"
      :class="`question_select_box ${importedDataStyling}`"
    ></v-text-field>
    <p v-else class="readonly_input_area">{{ controlValue }}</p>
  </div>
</template>

<script>
export default {
  props: ["value", "readOnlyInputs", "importedDataStyling"],
  data: function() {
    return {
      alphanumericRules: [
        v => !v || (isNaN(this.value) && /\d/.test(this.value)) || "Inválida !"
      ],
      controlValue: this.value
    };
  },
  watch: {
    value(newVal, oldVal) {
      this.controlValue = newVal;
    }
  },
  methods: {
    onChangeInput() {
      this.$emit("input", this.controlValue);
    }
  }
};
</script>

<style>
p.readonly_input_area {
  min-width: 100px;
  padding: 10px;
  background: #cfcfcf;
  border-bottom: 1px solid #888;
}
</style>
