<template>
  <div v-if="selectOptions.length > 0">
    <v-select
      :class="`question_select_box ${importedDataStyling}`"
      v-model="inputVal"
      item-text="label"
      item-value="value"
      :items="selectOptions"
      hide-details
      filled
      :multiple="multiple"
      :readonly="readOnlyInputs"
    ></v-select>
  </div>
  <b-alert v-else class="p-2 m-0" variant="danger" show>no hay opción.</b-alert>
</template>

<script>
export default {
  props: [
    "value",
    "label",
    "options",
    "optionScores",
    "readOnlyInputs",
    "importedDataStyling"
  ],
  data: function() {
    return {
      optionList: [],
      multiple: false,
      selectOptions: [],
      orderedOptionKeys: []
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  watch: {
    optionScores(newVal, oldVal) {
      if (newVal) {
        this.optimizeOptionList();
      }
    }
  },
  methods: {
    optimizeOptionList() {
      let newOptions = {};
      let selectOptionList = [];
      this.optionList = JSON.parse(JSON.stringify(this.options.values));
      for (const [optKey, optItem] of Object.entries(this.optionList)) {
        newOptions[optKey] = optItem;
        let pushOptionItem = { value: optKey, label: optItem };
        let optionValue = this.getScoreOfOption(optKey);
        if (!isNaN(optionValue) && optionValue) {
          newOptions[optKey] = `${optItem} - ${optionValue} pts`;
          pushOptionItem.label = `${optItem} - ${optionValue} pts`;
        }
        selectOptionList.push(pushOptionItem);
      }
      this.optionList = newOptions;
      if (this.orderedOptionKeys.length > 0) {
        this.selectOptions = this.orderedOptionKeys
          .map(optKey => {
            let found = selectOptionList.find(el => el.value == optKey);
            return found ? found : null;
          })
          .filter(el => el !== null);
      } else {
        this.selectOptions = selectOptionList;
      }
    },
    getScoreOfOption(optionKey) {
      let value = null;
      if (this.optionScores && this.optionScores?.PSValues) {
        if (Object.keys(this.optionScores?.PSValues).includes(optionKey)) {
          value = this.optionScores.PSValues[optionKey];
        }
      }
      return value;
    }
  },
  mounted() {
    this.optionList = JSON.parse(JSON.stringify(this.options.values));
    this.multiple = this.options.multi;
    let options = JSON.parse(JSON.stringify(this.options));
    if (options && options?.orderedKeys) {
      this.orderedOptionKeys = options.orderedKeys;
    }
    this.optimizeOptionList();
  }
};
</script>

<style>
.question_select_box .v-select__selection--comma {
  white-space: normal;
}
</style>
