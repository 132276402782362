<template>
  <div>
    <div
      class="w-full d-flex justify-content-center pb-2 position-relative"
      style="min-height: 50px;"
    >
      <v-icon
        x-large
        class="modal_close_button"
        @click="deleteImageItem"
        v-if="
          question &&
            attachments[0] &&
            attachments[0]?.worksessionSurveyResponsePhoto &&
            !question?.isFromLastResponse &&
            isTodayVisit &&
            this.ISFORCURRENTUSER
        "
      >
        mdi-close-circle-outline
      </v-icon>
      <v-btn
        v-if="question && isTodayVisit && this.ISFORCURRENTUSER"
        color="primary"
        dark
        class="btn_add_attachments"
        @click="onClickAddAttachments"
      >
        <v-icon>
          mdi-plus
        </v-icon>
        añadir foto
      </v-btn>
    </div>
    <div
      class="w-full d-flex justify-content-start pb-2 position-relative"
      style="min-height: 40px;"
    >
      <h6>
        {{ currentWorksessionDate }}
        {{
          this.userForCurrentSliderItem && !this.ISFORCURRENTUSER
            ? ` / ${this.userForCurrentSliderItem?.name} ${this.userForCurrentSliderItem?.surname}`
            : ""
        }}
      </h6>
      <b-icon
        v-if="
          this.worksessionSurveyPhotosPortal &&
            this.worksessionSurveyPhotosPortal?.isWarning !== null
        "
        class="mx-1 badge_warning"
        icon="exclamation-triangle-fill"
        font-scale="2"
        :variant="
          this.worksessionSurveyPhotosPortal?.isWarning == true
            ? `danger`
            : `success`
        "
      ></b-icon>
    </div>
    <div class="image-slider" v-if="slides.length > 0">
      <div
        class="slides"
        :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
      >
        <div v-for="(slide, index) in slides" :key="index" class="slide">
          <img :src="slide" alt="Slide" />
        </div>
      </div>
      <button
        class="btn_slider btn_prev"
        @click.prevent="prevSlide"
        v-show="currentIndex > 0"
      >
        <b-icon
          class="mx-1"
          variant="success"
          icon="arrow-left-circle"
          font-scale="3"
        ></b-icon>
      </button>
      <button
        class="btn_slider btn_next"
        @click.prevent="nextSlide"
        v-show="currentIndex < slides.length - 1"
      >
        <b-icon
          class="mx-1"
          variant="success"
          icon="arrow-right-circle"
          font-scale="3"
        ></b-icon>
      </button>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
export default {
  name: "ImageSlider",
  props: [
    "surveyResponseItem",
    "currentVisitCredential",
    "isTodayVisit",
    "worksessionSurveyPhotosPortal",
    "attachments",
    "question"
  ],
  data() {
    return {
      currentIndex: 0
    };
  },
  components: {},
  watch: {
    attachments(newVal, oldVal) {
      if (newVal.length > oldVal.length)
        this.currentIndex = this.attachments.length - 1;
      if (newVal.length > 0 && this.currentIndex > newVal.length - 1) {
        this.currentIndex = newVal.length - 1;
      }
    }
  },
  computed: {
    userForCurrentSliderItem() {
      let user = null;
      if (
        this?.surveyResponseItem?.worksession_survey?.worksession_pos_brand
          ?.worksession_po?.worksession?.user
      ) {
        user = this?.surveyResponseItem?.worksession_survey
          ?.worksession_pos_brand?.worksession_po?.worksession.user;
      }
      return user;
    },
    ISFORCURRENTUSER() {
      let ret = false;
      let userId = null;
      let currentSlideItemsUserId = null;
      if (this?.currentVisitCredential) {
        const { worksession } = this.currentVisitCredential;
        if (worksession && worksession?.userId) {
          userId = worksession?.userId;
        }
      }
      if (this.userForCurrentSliderItem) {
        currentSlideItemsUserId = this.userForCurrentSliderItem.id;
      }
      if (
        userId !== null &&
        currentSlideItemsUserId !== null &&
        userId == currentSlideItemsUserId
      ) {
        ret = true;
      } else if (
        !this.surveyResponseItem &&
        !this.userForCurrentSliderItem &&
        userId
      ) {
        ret = true;
      }
      return ret;
    },
    currentWorksessionDate() {
      let ret = null;
      if (this.currentVisitCredential) {
        const { worksession } = this.currentVisitCredential;
        if (worksession && worksession?.date) {
          ret = worksession.date;
        }
      }
      return ret;
    },
    slides() {
      let images = [];
      if (this.attachments) {
        this.attachments.map(el => {
          if (el && el?.worksessionSurveyResponsePhoto) {
            images.push(
              ApiService.getImageOrFileGlobalUri(
                el.worksessionSurveyResponsePhoto
              )
            );
          } else {
            images.push(URL.createObjectURL(el));
          }
        });
      }
      return images;
    }
  },
  methods: {
    onClickAddAttachments() {
      this.$emit("onUploadAttachment");
    },
    deleteImageItem() {
      if (this.attachments.length > this.currentIndex) {
        const currentAttachmentItem = this.attachments[this.currentIndex];
        const delAttachmentId = currentAttachmentItem.id;
        this.$emit("deleteAttachmentItem", delAttachmentId);
      }
    },
    nextSlide() {
      if (this.currentIndex < this.slides.length - 1) {
        this.currentIndex++;
      }
    },
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    }
  }
};
</script>

<style scoped>
.badge_warning {
  position: absolute;
  right: 10%;
}
.btn_add_attachments {
  max-width: 200px;
  position: absolute;
  right: 0;
}
.image-slider {
  overflow: hidden;
  position: relative;
}

.slides {
  display: flex;
  transition: transform 0.3s ease;
}

.slide {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-slider img {
  max-width: 100%;
  max-height: 50vh;
}

button.btn_slider {
  position: absolute;
  top: 50%;
}
button.btn_slider.btn_next {
  right: 2rem;
}
button.btn_slider.btn_prev {
  left: 2rem;
}
</style>
