<template>
  <div>
    <template v-for="(labelItem, kLabel) in postaglabels">
      <b-badge
        v-if="isActiveLabel(labelItem)"
        v-bind:key="`tagitem_${kLabel}`"
        variant="success"
        class="mx-2"
        :style="`background-color: ${labelItem.bgColorValue}`"
      >
        {{ labelItem.brand ? labelItem.brand.name : "" }}:
        {{ labelItem.name }}
      </b-badge>
    </template>
  </div>
</template>

<script>
import { ellipsis } from "@/utils";

export default {
  name: "sessionTagLabels",
  props: ["postaglabels"],

  data: function() {
    return {
      curDate: new Date().toISOString().slice(0, 10),
    };
  },

  computed: {},

  methods: {
    isActiveLabel(labelItem) {
      return (
        labelItem.startDate <= this.curDate && this.curDate <= labelItem.endDate
      );
    },
  },
};
</script>

<style></style>
