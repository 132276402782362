<template>
  <b-form-datepicker
    :date-format-options="{
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }"
    size="md"
    locale="es"
    v-model="dateValue"
    placeholder="fecha"
    :clear-button="true"
    @input="onInputChanged"
    :readonly="readOnlyInputs"
    :class="`surveyquestion-datepicker ${importedDataStyling}`"
  >
  </b-form-datepicker>
</template>

<script>
import moment from "moment";

export default {
  props: ["value", "readOnlyInputs", "importedDataStyling"],

  components: {},

  data() {
    return {
      dateValue: null,
    };
  },

  mounted() {
    if (this.value && this.value !== "") {
      this.dateValue = moment(this.value).format("YYYY-MM-DD");
    }
  },

  methods: {
    onInputChanged() {
      this.$emit("input", this.dateValue);
    },
  },
};
</script>

<style></style>
