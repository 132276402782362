<template>
  <div :class="`${importedDataStyling} input_checkbox`">
    <template v-if="orderedOptionKeys.length > 0">
      <template v-for="optionKey in orderedOptionKeys">
        <v-checkbox
          v-bind:key="`question_selectbox_option_${optionKey}`"
          :label="optionList[optionKey]"
          :value="optionKey"
          multiple
          hide-details
          v-model="inputVal"
          :readonly="readOnlyInputs"
        ></v-checkbox>
      </template>
    </template>
    <template v-else>
      <template v-for="(optionItem, optionKey) in optionList">
        <v-checkbox
          v-bind:key="`question_selectbox_option_${optionKey}`"
          :label="optionItem"
          :value="optionKey"
          multiple
          hide-details
          v-model="inputVal"
          :readonly="readOnlyInputs"
        ></v-checkbox>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: [
    "value",
    "label",
    "options",
    "optionScores",
    "readOnlyInputs",
    "importedDataStyling"
  ],
  data: function() {
    return {
      optionList: [],
      orderedOptionKeys: []
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  watch: {
    optionScores(newVal, oldVal) {
      if (newVal) {
        this.optimizeOptionList();
      }
    }
  },
  methods: {
    optimizeOptionList() {
      let newOptions = {};
      this.optionList = JSON.parse(JSON.stringify(this.options.values));
      for (const [optKey, optItem] of Object.entries(this.optionList)) {
        newOptions[optKey] = optItem;
        let optionValue = this.getScoreOfOption(optKey);
        if (!isNaN(optionValue) && optionValue) {
          newOptions[optKey] = `${optItem} - ${optionValue} pts`;
        }
      }
      this.optionList = newOptions;
    },
    getScoreOfOption(optionKey) {
      let value = null;
      if (this.optionScores && this.optionScores?.PSValues) {
        if (Object.keys(this.optionScores?.PSValues).includes(optionKey)) {
          value = this.optionScores.PSValues[optionKey];
        }
      }
      return value;
    }
  },
  mounted() {
    this.optionList = JSON.parse(JSON.stringify(this.options.values));
    let options = JSON.parse(JSON.stringify(this.options));
    if (options && options?.orderedKeys) {
      this.orderedOptionKeys = options.orderedKeys;
    }
    this.optimizeOptionList();
  }
};
</script>

<style></style>
