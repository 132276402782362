<template>
  <div>
    <input
      :class="
        `survey-input-xs survey-input-number-xs ${this.importedDataStyling}`
      "
      v-model="controlValue"
      @change="onChangeInput"
      type="number"
      placeholder="0.00"
      required
      value="0"
      step="any"
      @keydown="handleKeyDown"
      :readonly="readOnlyInputs"
    />
    <kpiObjectiveSpan
      :isKPIthisQuestion="isKPIthisQuestion"
      :foundObjectiveValue="foundObjectiveValue"
    />
  </div>
</template>

<script>
import kpiObjectiveSpan from "./kpiObjectiveSpan";

export default {
  props: [
    "value",
    "label",
    "readOnlyInputs",
    "isKPIthisQuestion",
    "foundObjectiveValue",
    "importedDataStyling"
  ],
  components: {
    kpiObjectiveSpan
  },
  data: function() {
    return {
      controlValue: this.value
    };
  },
  watch: {
    value(newVal, oldVal) {
      this.controlValue = newVal;
    }
  },
  methods: {
    handleKeyDown(event) {
      console.log("Key code:", event.keyCode);
      // handle keydown event here
      if (event.keyCode === 38 || event.keyCode === 40) {
        event.preventDefault();
      }
    },
    onChangeInput() {
      this.$emit("input", this.controlValue);
    }
  }
};
</script>

<style>
input.survey-input-number-xs::-webkit-inner-spin-button,
input.survey-input-number-xs::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  pointer-events: none;
}
</style>
