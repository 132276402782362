<template>
  <div>
    <v-overlay :value="isLoadingSpinner" :opacity="`0.5`" zIndex="99999"
      ><v-progress-circular
        indeterminate
        size="70"
        width="7"
        color="primary"
      ></v-progress-circular
    ></v-overlay>
    <modal
      class="photo_modal"
      :name="`modal-photo-detail_${currentProOrMatId}_${question?.id}`"
      :width="`90%`"
      :height="`auto`"
      scrollable
    >
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text class="objective_content">
            <span class="text-h5">{{ modalTitle }}</span>
          </v-card-text>

          <v-card-text>
            <div class="mt-2 text--primary"></div>

            <b-alert show variant="info" class="mt-2" v-if="isLoading">
              <b-icon
                icon="arrow-counterclockwise"
                animation="spin-reverse"
              ></b-icon>
              cargando datos...
            </b-alert>
            <template v-else>
              <v-row class="portal-card-slider">
                <b-icon
                  class="my-1 btn-portal-card-slider portal-card-slider-prev"
                  variant="primary"
                  icon="caret-left-fill"
                  font-scale="3"
                  v-if="movePrevVisitSurveyResponse"
                  @click="slide('left')"
                ></b-icon>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  v-if="lastVisitCredential && last_survey_response"
                >
                  <v-row>
                    <v-col cols="12" md="12" class="text-center">
                      <b-icon
                        class="my-1 btn-portal-move-pos"
                        variant="primary"
                        icon="caret-up-fill"
                        font-scale="3"
                        @click.prevent="prevSlide('LAST_SURVEY')"
                        v-show="currentLastSurveyIndex > 0"
                      ></b-icon>
                    </v-col>
                  </v-row>
                  <div
                    class="survey_response_slider"
                    v-if="this.lastWorksessionSurveyResponses.length > 0"
                  >
                    <div
                      class="survey_response_slides"
                      :style="{
                        transform: `translateX(-${currentLastSurveyIndex *
                          100}%)`
                      }"
                    >
                      <div
                        v-for="(last_survey_response_item, last_key) in this
                          .lastWorksessionSurveyResponses"
                        :key="`last_survey_response_item_${last_key}`"
                        class="survey_response_slide_item"
                      >
                        <div>
                          <ImageSlider
                            :isTodayVisit="false"
                            :surveyResponseItem="last_survey_response_item"
                            :currentVisitCredential="lastVisitCredential"
                            :worksessionSurveyPhotosPortal="
                              last_survey_response_item?.worksession_survey_photos_portal
                            "
                            :question="question"
                            :attachments="
                              last_survey_response_item?.worksession_survey_response_attachments
                            "
                          />
                          <PortalDetail
                            :survey_response_item="last_survey_response_item"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-row>
                    <v-col cols="12" md="12" class="text-center">
                      <b-icon
                        class="my-1 btn-portal-move-pos"
                        variant="primary"
                        icon="caret-down-fill"
                        font-scale="3"
                        @click.prevent="nextSlide('LAST_SURVEY')"
                        v-show="
                          currentLastSurveyIndex <
                            this.lastWorksessionSurveyResponses.length - 1
                        "
                      ></b-icon>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  v-if="
                    (currentVisitCredential && current_survey_response) ||
                      isTodayVisit
                  "
                >
                  <v-row>
                    <v-col cols="12" md="12" class="text-center">
                      <b-icon
                        class="my-1 btn-portal-move-pos"
                        variant="primary"
                        icon="caret-up-fill"
                        font-scale="3"
                        @click.prevent="prevSlide('CURRENT_SURVEY')"
                        v-show="currentSurveyIndex > 0"
                      ></b-icon>
                    </v-col>
                  </v-row>
                  <div class="survey_response_slider">
                    <div
                      class="survey_response_slides"
                      :style="{
                        transform: `translateX(-${currentSurveyIndex * 100}%)`
                      }"
                    >
                      <div class="survey_response_slide_item">
                        <div>
                          <ImageSlider
                            :isTodayVisit="isTodayVisit"
                            :surveyResponseItem="current_survey_response"
                            :currentVisitCredential="currentVisitCredential"
                            :worksessionSurveyPhotosPortal="
                              worksessionSurveyPhotosPortal
                            "
                            :question="question"
                            :attachments="current_attachments"
                            @deleteAttachmentItem="
                              delId => deleteAttachmentItem(delId)
                            "
                            @onUploadAttachment="
                              () => onClickUploadAttachment()
                            "
                          />
                          <div class="mt-2 text--primary">
                            <v-row>
                              <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                lg="4"
                                v-if="saveData"
                              >
                                <h6 class="my-2 text-center">GPV</h6>
                                <div class="d-flex justify-content-center">
                                  <v-rating
                                    background-color="pink lighten-3"
                                    color="pink"
                                    dense
                                    size="28"
                                    v-model="saveData.scoreGPV"
                                    :readonly="!isTodayVisit"
                                  ></v-rating>
                                </div>
                                <v-textarea
                                  :label="$t('comments')"
                                  hide-details
                                  v-model="saveData.commentsGPV"
                                  :readonly="!isTodayVisit"
                                  v-bind:class="
                                    `gpv_comment_text ${
                                      !isTodayVisit ? 'readonly' : ''
                                    }`
                                  "
                                ></v-textarea>
                              </v-col>
                              <v-col
                                cols="12"
                                lg="4"
                                md="12"
                                sm="12"
                                v-if="this.worksessionSurveyPhotosPortal"
                              >
                                <h6 class="my-2 text-center">
                                  {{ $t("salesforce.Parent") }}
                                </h6>
                                <div class="d-flex justify-content-center">
                                  <v-rating
                                    background-color="pink lighten-3"
                                    color="pink"
                                    dense
                                    size="28"
                                    v-model="
                                      this.worksessionSurveyPhotosPortal
                                        .scoreBrand
                                    "
                                    :readonly="true"
                                  ></v-rating>
                                </div>
                                <v-textarea
                                  :label="$t('comments')"
                                  hide-details
                                  v-model="
                                    this.worksessionSurveyPhotosPortal
                                      .commentsBrand
                                  "
                                  :readonly="true"
                                  v-bind:class="'gpv_comment_text readonly'"
                                ></v-textarea>
                              </v-col>
                              <v-col
                                cols="12"
                                lg="4"
                                md="12"
                                sm="12"
                                v-if="this.worksessionSurveyPhotosPortal"
                              >
                                <h6 class="my-2 text-center">
                                  {{ $t("brands.brand") }}
                                </h6>
                                <div class="d-flex justify-content-center">
                                  <v-rating
                                    background-color="pink lighten-3"
                                    color="pink"
                                    dense
                                    size="28"
                                    v-model="
                                      this.worksessionSurveyPhotosPortal
                                        .scoreAdmin
                                    "
                                    :readonly="true"
                                  ></v-rating>
                                </div>
                                <v-textarea
                                  :label="$t('comments')"
                                  hide-details
                                  v-model="
                                    this.worksessionSurveyPhotosPortal
                                      .commentsAdmin
                                  "
                                  :readonly="true"
                                  v-bind:class="'gpv_comment_text readonly'"
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </div>
                      <div
                        v-for="(current_survey_response_item,
                        current_key) in this
                          .currentWorksessionOtherSurveyResponses"
                        :key="`current_survey_response_item_${current_key}`"
                        class="survey_response_slide_item"
                      >
                        <div>
                          <ImageSlider
                            :isTodayVisit="isTodayVisit"
                            :surveyResponseItem="current_survey_response_item"
                            :currentVisitCredential="currentVisitCredential"
                            :worksessionSurveyPhotosPortal="
                              current_survey_response_item?.worksession_survey_photos_portal
                            "
                            :question="question"
                            :attachments="
                              current_survey_response_item?.worksession_survey_response_attachments
                            "
                          />
                          <PortalDetail
                            :survey_response_item="current_survey_response_item"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-row>
                    <v-col cols="12" md="12" class="text-center">
                      <b-icon
                        class="my-1 btn-portal-move-pos"
                        variant="primary"
                        icon="caret-down-fill"
                        font-scale="3"
                        @click.prevent="nextSlide('CURRENT_SURVEY')"
                        v-show="
                          currentSurveyIndex <
                            this.currentWorksessionOtherSurveyResponses.length
                        "
                      ></b-icon>
                    </v-col>
                  </v-row>
                </v-col>
                <b-icon
                  class="my-1 btn-portal-card-slider portal-card-slider-next"
                  variant="primary"
                  icon="caret-right-fill"
                  font-scale="3"
                  v-if="moveNextVisitSurveyResponse"
                  @click="slide('right')"
                ></b-icon>
              </v-row>
            </template>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              @click="onClickSaveComments"
              v-if="isTodayVisit"
            >
              {{ $t("save") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="hideModal()">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import ImageSlider from "./ImageSlider.vue";
import PortalDetail from "./PortalDetail.vue";
import download from "js-file-download";
import { logInfo, logError } from "@/utils";

export default {
  name: "DialogPhotoDetail",
  props: [
    "worksessionPosBrandId",
    "onUploadAttachment",
    "onClickUploadAttachment",
    "attachments",
    "surveyListItem",
    "component",
    "proOrMat",
    "question",
    "assignTypes",
    "currentProOrMatId",
    // "worksessionSurveyPhotosPortal",
    "commentsPhotosPortal",
    "punctuationLabel"
  ],
  data() {
    return {
      currentSurveyIndex: 0,
      currentLastSurveyIndex: 0,
      isLoading: true,
      // attachments: [],
      prevVisitCredential: null,
      prev_survey_response: null,
      lastVisitCredential: null,
      last_survey_response: null,
      currentVisitCredential: null,
      current_survey_response: null,
      nextVisitCredential: null,
      next_survey_response: null,
      next2VisitCredential: null,
      next2_survey_response: null,
      saveData: null,

      isLoadingSpinner: false
    };
  },
  components: { ImageSlider, PortalDetail },
  watch: {},
  computed: {
    moveNextVisitSurveyResponse() {
      let ret = null;
      const {
        nextVisitCredential,
        next_survey_response,
        next2VisitCredential,
        next2_survey_response
      } = this;
      if (next2VisitCredential && next2_survey_response) {
        ret = {
          visitCredential: next2VisitCredential,
          visitSurveyResponse: next2_survey_response
        };
      } else if (nextVisitCredential && next_survey_response) {
        ret = {
          visitCredential: nextVisitCredential,
          visitSurveyResponse: next_survey_response
        };
      }
      return ret;
    },
    movePrevVisitSurveyResponse() {
      let ret = null;
      const {
        lastVisitCredential,
        last_survey_response,
        prevVisitCredential,
        prev_survey_response
      } = this;
      if (prevVisitCredential && prev_survey_response) {
        ret = {
          visitCredential: prevVisitCredential,
          visitSurveyResponse: prev_survey_response
        };
      } else if (lastVisitCredential && last_survey_response) {
        ret = {
          visitCredential: lastVisitCredential,
          visitSurveyResponse: last_survey_response
        };
      }
      return ret;
    },
    lastWorksessionSurveyResponses() {
      let ret = [];
      if (this?.last_survey_response) {
        ret.push({ ...this.last_survey_response });
      }
      if (
        this?.last_others_survey_responses &&
        this.last_others_survey_responses.length > 0
      ) {
        ret = [...ret, ...this.last_others_survey_responses];
      }
      if (ret.length > 0) {
        ret = ret.map(el => {
          let pushItem = { ...el };
          if (!pushItem?.worksession_survey_photos_portal) {
            pushItem.worksession_survey_photos_portal = {
              isWarning: null,
              scoreGPV: null,
              scoreBrand: null,
              scoreAdmin: null,
              commentsGPV: null,
              commentsBrand: null,
              commentsAdmin: null
            };
          }
          return pushItem;
        });
      }
      return ret;
    },
    currentWorksessionOtherSurveyResponses() {
      let ret = [];
      if (
        this?.current_others_survey_responses &&
        this.current_others_survey_responses.length > 0
      ) {
        ret = [...ret, ...this.current_others_survey_responses];
      }
      if (ret.length > 0) {
        ret = ret.map(el => {
          let pushItem = { ...el };
          if (!pushItem?.worksession_survey_photos_portal) {
            pushItem.worksession_survey_photos_portal = {
              isWarning: null,
              scoreGPV: null,
              scoreBrand: null,
              scoreAdmin: null,
              commentsGPV: null,
              commentsBrand: null,
              commentsAdmin: null
            };
          }
          return pushItem;
        });
      }
      return ret;
    },
    worksessionSurveyPhotosPortal() {
      let ret = {
        isWarning: null,
        scoreGPV: null,
        scoreBrand: null,
        scoreAdmin: null,
        commentsGPV: null,
        commentsBrand: null,
        commentsAdmin: null
      };
      if (
        this.current_survey_response &&
        this.current_survey_response?.worksession_survey_photos_portal
      ) {
        ret = this.current_survey_response?.worksession_survey_photos_portal;
      }
      return ret;
    },
    current_attachments() {
      let ret = null;
      if (this.isTodayVisit) {
        ret = this.attachments;
      } else if (
        this.current_survey_response &&
        this.current_survey_response?.worksession_survey_response_attachments
      ) {
        ret = this.current_survey_response
          ?.worksession_survey_response_attachments;
      }
      return ret;
    },
    isTodayVisit() {
      let ret = false;
      if (
        this.currentVisitCredential &&
        this.currentVisitCredential?.worksessionPosBrandId &&
        this.currentVisitCredential?.worksessionPosBrandId ==
          this.worksessionPosBrandId
      ) {
        ret = true;
      }
      return ret;
    },
    modalTitle() {
      let titles = [];
      if (this.surveyListItem && this.surveyListItem?.name)
        titles.push(this.surveyListItem?.name);
      if (this.component && this.component?.name)
        titles.push(this.component?.name);
      if (
        this.component &&
        this.component?.assignType &&
        this.assignTypes[this.component?.assignType]
      )
        titles.push(this.assignTypes[this.component?.assignType]);
      if (this.proOrMat && this.proOrMat?.name)
        titles.push(this.proOrMat?.name);
      if (this.question && this.question?.name)
        titles.push(this.question?.name);
      if (this.question && this.question?.id) titles.push(this.question?.id);
      if (titles.length > 0) {
        return titles.join(" / ");
      }
      return "Photo Details";
    }
  },
  methods: {
    nextSlide(slideKey) {
      if (slideKey === "LAST_SURVEY") {
        if (
          this.currentLastSurveyIndex <
          this.lastWorksessionSurveyResponses.length - 1
        ) {
          this.currentLastSurveyIndex++;
        }
      } else if (slideKey === "CURRENT_SURVEY") {
        if (
          this.currentSurveyIndex <
          this.currentWorksessionOtherSurveyResponses.length
        ) {
          this.currentSurveyIndex++;
        }
      }
    },
    prevSlide(slideKey) {
      if (slideKey === "LAST_SURVEY") {
        if (this.currentLastSurveyIndex > 0) {
          this.currentLastSurveyIndex--;
        }
      } else if (slideKey === "CURRENT_SURVEY") {
        if (this.currentSurveyIndex > 0) {
          this.currentSurveyIndex--;
        }
      }
    },
    async slide(direction) {
      if (direction === "left") {
        const { visitCredential } = this.movePrevVisitSurveyResponse;
        await this.checkPrevNextPhotos(visitCredential);
      } else if (direction === "right") {
        const { visitCredential } = this.moveNextVisitSurveyResponse;
        await this.checkPrevNextPhotos(visitCredential);
      }
      this.createSaveData();
    },
    async checkPrevNextPhotos(visitCredential) {
      let move_worksession_pos_brand_id = this.worksessionPosBrandId;
      if (visitCredential && visitCredential?.worksessionPosBrandId) {
        move_worksession_pos_brand_id = visitCredential?.worksessionPosBrandId;
      }
      this.isLoading = true;
      this.isLoadingSpinner = true;
      try {
        let body = {
          worksessionPosBrandId: move_worksession_pos_brand_id,
          surveyId: this.surveyListItem.id,
          componentId: this.component.id,
          currentProOrMatId: this.currentProOrMatId,
          questionId: this.question.id,
          assignType: this.component.assignType
        };
        const resp = await ApiService.post(
          `worksession/survey/checkPrevNextPhotos`,
          body
        );
        this.isLoading = false;
        if (resp?.success) {
          const {
            prevVisitCredential,
            prev_survey_response,
            next2VisitCredential,
            next2_survey_response,
            lastVisitCredential,
            last_survey_response,
            currentVisitCredential,
            current_survey_response,
            current_others_survey_responses,
            last_others_survey_responses,
            nextVisitCredential,
            next_survey_response
          } = resp;
          this.prevVisitCredential = prevVisitCredential;
          this.prev_survey_response = prev_survey_response;
          this.lastVisitCredential = lastVisitCredential;
          this.last_survey_response = last_survey_response;
          this.currentVisitCredential = currentVisitCredential;
          this.current_survey_response = current_survey_response;
          this.nextVisitCredential = nextVisitCredential;
          this.next_survey_response = next_survey_response;
          this.next2VisitCredential = next2VisitCredential;
          this.next2_survey_response = next_survey_response;
          this.current_others_survey_responses = current_others_survey_responses;
          this.last_others_survey_responses = last_others_survey_responses;
          this.currentSurveyIndex = 0;
          this.currentLastSurveyIndex = 0;
          this.createSaveData();
          this.$modal.show(
            `modal-photo-detail_${this.currentProOrMatId}_${this.question?.id}`
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
      this.isLoadingSpinner = false;
    },
    deleteAttachmentItem(delId) {
      // Delete image item
      if (window.confirm(this.$t("confirmdelete"))) {
        this.$emit("deleteAttachment", delId);
      }
    },
    createSaveData() {
      this.saveData = {
        commentsGPV: null,
        scoreGPV: 0,
        worksessionSurveyResponseId: null
        // scoreGPVBy: 1 - userId
      };
      if (this.worksessionSurveyPhotosPortal) {
        const {
          commentsGPV,
          scoreGPV,
          worksessionSurveyResponseId
        } = this.worksessionSurveyPhotosPortal;
        this.saveData = {
          commentsGPV,
          scoreGPV,
          worksessionSurveyResponseId
        };
      }
    },
    async showModal(question, attachments) {
      this.checkPrevNextPhotos();
    },
    hideModal() {
      this.$modal.hide(
        `modal-photo-detail_${this.currentProOrMatId}_${this.question?.id}`
      );
    },
    onClickSaveComments() {
      if (!this.saveData) return false;
      let validation = true;
      const { isRequiredPortalComment, isRequiredPortalScore } = this.question;
      if (
        isRequiredPortalComment &&
        (!this.saveData.commentsGPV || this.saveData.commentsGPV == "")
      ) {
        validation = false;
      }
      if (isRequiredPortalScore && !this.saveData.scoreGPV) {
        validation = false;
      }
      if (!validation) {
        logError("Debes dejar comentarios y puntuaciones para el portal.");
        return;
      }
      this.$emit("onClickSaveComments", this.saveData);
      this.hideModal();
    },
    async downloadAllImages() {
      for (const el of this.attachments) {
        if (el && el?.worksessionSurveyResponsePhoto) {
          let imageUri = ApiService.getImageOrFileGlobalUri(
            el.worksessionSurveyResponsePhoto
          );
          try {
            const response = await ApiService.get(imageUri, {
              responseType: "arraybuffer"
            });
            download(response, "survey_photos.png");
          } catch (error) {
            console.log("error - ", error);
          }
        }
      }
    }
  }
};
</script>

<style>
.portal-card-slider {
  position: relative;
  padding: 0 4rem;
}
.btn-portal-card-slider {
  position: absolute;
  top: 50%;
  cursor: pointer;
}
.portal-card-slider-prev {
  left: 1rem;
}
.portal-card-slider-next {
  right: 1rem;
}
.btn-portal-move-pos {
  cursor: pointer;
}
.survey_response_slider {
  overflow: hidden;
  position: relative;
}
.survey_response_slides {
  display: flex;
  transition: transform 0.3s ease;
}
.survey_response_slide_item {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.photo_modal {
  min-height: 100% !important; /* Adjust as needed */
  overflow-y: auto;
  overflow-y: scroll; /* Standard syntax */
  overflow-y: -webkit-scroll; /* Chrome, Safari, iOS, Android */
  overflow-y: -moz-scrollbars-vertical; /* Firefox */
  -ms-overflow-y: auto; /* Internet Explorer */
}
</style>
