var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 text--primary"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"4"}},[_c('h6',{staticClass:"my-2 text-center"},[_vm._v("GPV")]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-rating',{attrs:{"background-color":"pink lighten-3","color":"pink","dense":"","size":"28","readonly":true},model:{value:(
            _vm.survey_response_item.worksession_survey_photos_portal.scoreGPV
          ),callback:function ($$v) {_vm.$set(_vm.survey_response_item.worksession_survey_photos_portal, "scoreGPV", $$v)},expression:"\n            survey_response_item.worksession_survey_photos_portal.scoreGPV\n          "}})],1),_c('v-textarea',{class:'gpv_comment_text readonly',attrs:{"label":_vm.$t('comments'),"hide-details":"","readonly":true},model:{value:(
          _vm.survey_response_item.worksession_survey_photos_portal.commentsGPV
        ),callback:function ($$v) {_vm.$set(_vm.survey_response_item.worksession_survey_photos_portal, "commentsGPV", $$v)},expression:"\n          survey_response_item.worksession_survey_photos_portal.commentsGPV\n        "}})],1),(_vm.survey_response_item?.worksession_survey_photos_portal)?_c('v-col',{attrs:{"cols":"12","lg":"4","md":"12","sm":"12"}},[_c('h6',{staticClass:"my-2 text-center"},[_vm._v(" "+_vm._s(_vm.$t("salesforce.Parent"))+" ")]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-rating',{attrs:{"background-color":"pink lighten-3","color":"pink","dense":"","size":"28","readonly":true},model:{value:(
            _vm.survey_response_item.worksession_survey_photos_portal.scoreBrand
          ),callback:function ($$v) {_vm.$set(_vm.survey_response_item.worksession_survey_photos_portal, "scoreBrand", $$v)},expression:"\n            survey_response_item.worksession_survey_photos_portal.scoreBrand\n          "}})],1),_c('v-textarea',{class:'gpv_comment_text readonly',attrs:{"label":_vm.$t('comments'),"hide-details":"","readonly":true},model:{value:(
          _vm.survey_response_item.worksession_survey_photos_portal.commentsBrand
        ),callback:function ($$v) {_vm.$set(_vm.survey_response_item.worksession_survey_photos_portal, "commentsBrand", $$v)},expression:"\n          survey_response_item.worksession_survey_photos_portal.commentsBrand\n        "}})],1):_vm._e(),(_vm.survey_response_item?.worksession_survey_photos_portal)?_c('v-col',{attrs:{"cols":"12","lg":"4","md":"12","sm":"12"}},[_c('h6',{staticClass:"my-2 text-center"},[_vm._v(" "+_vm._s(_vm.$t("brands.brand"))+" ")]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-rating',{attrs:{"background-color":"pink lighten-3","color":"pink","dense":"","size":"28","readonly":true},model:{value:(
            _vm.survey_response_item.worksession_survey_photos_portal.scoreAdmin
          ),callback:function ($$v) {_vm.$set(_vm.survey_response_item.worksession_survey_photos_portal, "scoreAdmin", $$v)},expression:"\n            survey_response_item.worksession_survey_photos_portal.scoreAdmin\n          "}})],1),_c('v-textarea',{class:'gpv_comment_text readonly',attrs:{"label":_vm.$t('comments'),"hide-details":"","readonly":true},model:{value:(
          _vm.survey_response_item.worksession_survey_photos_portal.commentsAdmin
        ),callback:function ($$v) {_vm.$set(_vm.survey_response_item.worksession_survey_photos_portal, "commentsAdmin", $$v)},expression:"\n          survey_response_item.worksession_survey_photos_portal.commentsAdmin\n        "}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }