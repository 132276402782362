<template>
  <div v-if="Object.keys(optionList).length > 0" class="p-0">
    <div v-if="multiple" :class="`${importedDataStyling} input_checkbox`">
      <template v-for="(optionItem, optionKey) in optionList">
        <v-checkbox
          v-bind:key="`question_selectbox_option_${optionKey}`"
          :label="optionItem.label"
          :value="optionKey"
          multiple
          hide-details
          v-model="inputVal"
          :readonly="readOnlyInputs"
        ></v-checkbox>
      </template>
    </div>
    <div v-else>
      <v-radio-group
        v-model="inputVal"
        :readonly="readOnlyInputs"
        :class="`${importedDataStyling}`"
      >
        <v-radio
          v-for="(optionItem, optionKey) in optionList"
          :key="optionKey"
          :label="`${optionItem.label}`"
          :value="optionKey"
          hide-details
        ></v-radio>
      </v-radio-group>
    </div>
  </div>
  <b-alert v-else class="p-2 m-0" variant="danger" show>no hay opción.</b-alert>
</template>

<script>
export default {
  props: [
    "value",
    "label",
    "options",
    "optionScores",
    "readOnlyInputs",
    "importedDataStyling",
  ],
  data: function() {
    return {
      optionList: [],
      multiple: false,
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    optionScores(newVal, oldVal) {
      if (newVal) {
        this.optimizeOptionList();
      }
    },
  },
  methods: {
    optimizeOptionList() {
      let newOptions = {};
      this.optionList = JSON.parse(JSON.stringify(this.options.values));
      for (const [optKey, optItem] of Object.entries(this.optionList)) {
        newOptions[optKey] = optItem;
        let optionValue = this.getScoreOfOption(optKey);
        if (!isNaN(optionValue) && optionValue) {
          newOptions[optKey]["label"] = `${optItem.label} - ${optionValue} pts`;
        }
      }
      this.optionList = newOptions;
    },
    getScoreOfOption(optionKey) {
      let value = null;
      if (this.optionScores && this.optionScores?.PSValues) {
        if (Object.keys(this.optionScores?.PSValues).includes(optionKey)) {
          value = this.optionScores.PSValues[optionKey];
        }
      }
      return value;
    },
  },
  mounted() {
    this.optionList = JSON.parse(JSON.stringify(this.options.values));
    this.multiple = this.options.multi;
    this.optimizeOptionList();
  },
};
</script>

<style></style>
