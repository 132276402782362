var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.component.proOrMatList),function(proOrMat,proOrMatKey){return _c('v-row',{key:`component_promat_${proOrMatKey}`},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(proOrMat.isVisible || proOrMat.isVisibleAction),expression:"proOrMat.isVisible || proOrMat.isVisibleAction"}]},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6"}},[(proOrMat)?_c('h5',{staticClass:"text-right"},[_vm._v(" "+_vm._s(proOrMat.name)+" ")]):_vm._e()]),(proOrMat)?_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6"}},[(
              _vm.getProOrMatImageComponent(_vm.component.assignType, proOrMat, true)
            )?_c('img',{staticStyle:{"border":"1px solid #aaa","max-width":"80%","cursor":"pointer"},attrs:{"src":_vm.getProOrMatImageComponent(_vm.component.assignType, proOrMat, true),"alt":""},on:{"click":function($event){return _vm.onItemShowImageDialog(_vm.component.assignType, proOrMat, false)}}}):_vm._e(),(proOrMat.alertList.length > 0)?_c('b-button',{staticClass:"ml-2 btn_color_white",attrs:{"size":"sm","variant":proOrMat.isReadProductAlert ? `success` : `danger`},on:{"click":function($event){return _vm.onClickProductAlert(
                proOrMat.alertList,
                _vm.surveyListItem,
                _vm.component,
                proOrMat
              )}}},[_c('b-icon',{attrs:{"icon":"alarm-fill"}})],1):_vm._e()],1):_vm._e()],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(proOrMat.isVisible || proOrMat.isVisibleAction),expression:"proOrMat.isVisible || proOrMat.isVisibleAction"}]},[_vm._l((proOrMat.sortedKeysQuestion),function(questionId){return [_vm._l(([
              _vm.getQuestionItem(proOrMat.questions, questionId),
            ]),function(question){return [(question)?_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(question.isVisible && question.isCondVisible),expression:"question.isVisible && question.isCondVisible"}],key:`component_proOrMat_${_vm.surveyListKey}_${_vm.comKey}_${proOrMatKey}_question_${question.id}`,attrs:{"cols":"12","xs":"12","sm":![_vm.QUESTION_TYPES.LONGTEXT].includes(question.questionType)
                  ? 6
                  : 12,"md":![_vm.QUESTION_TYPES.LONGTEXT].includes(question.questionType)
                  ? 3
                  : 12}},[_c('SurveyQuestion',{ref:`survey_question_ref_${proOrMatKey}_${question.id}`,refInFor:true,attrs:{"detectedSurveyChainIdForPuncDetails":_vm.detectedSurveyChainIdForPuncDetails,"worksession_survey_response_approval":question.worksession_survey_response_approval,"optionScores":question.optionScores,"initialValue":question.value,"currentScore":question.score,"maximumScore":question.maximumScore,"question":question},on:{"onClickCompQueImage":(image) => _vm.onClickCompQueImage(image),"onClickCorrectionAlert":(worksession_survey_response_approval) =>
                    _vm.onClickCorrectionAlert(
                      worksession_survey_response_approval
                    ),"input":(result, typeStr) =>
                    _vm.persistQuestionValue(
                      result,
                      typeStr,
                      _vm.surveyListItem.id,
                      _vm.component.id,
                      proOrMat?.id ? proOrMat.id : null,
                      question.id,
                      _vm.component.assignType,
                      question,
                      `survey_question_ref_${proOrMatKey}_${question.id}`,
                      question.isCondVisible
                    )}})],1):_vm._e()]})]})],2),(_vm.component.isAction)?_c('v-row',[_c('v-col',{staticClass:"text-left d-flex align-center",attrs:{"cols":"12","xs":"12","sm":"3"}},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(proOrMat.isVisible || proOrMat.isVisibleAction),expression:"proOrMat.isVisible || proOrMat.isVisibleAction"}],staticClass:"mr-1",staticStyle:{"color":"#fff !important"},attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.onClickUpsertActions(
                _vm.surveyListItem,
                _vm.component,
                proOrMat,
                null,
                null
              )}}},[_c('b-icon',{attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.$t("Add Action"))+" ")],1)],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","xs":"12","sm":"9"}},_vm._l((proOrMat?.actions),function(actionItem,actionKey){return _c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(actionItem.isVisible),expression:"actionItem.isVisible"}],key:`add_action_item_${actionKey}`,class:`m-2 added_action_chip ${
                actionItem.actionType === 'PROMOLOCAL'
                  ? 'chip_promo_local'
                  : ''
              }`,attrs:{"close":""},on:{"click:close":function($event){return _vm.onClickRemoveAction(
                _vm.surveyListItem,
                _vm.component,
                proOrMat,
                actionKey
              )}}},[_c('div',{staticClass:"px-2 py-2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onClickUpsertActions(
                  _vm.surveyListItem,
                  _vm.component,
                  proOrMat,
                  actionItem,
                  actionKey
                )}}},[_vm._v(" "+_vm._s(actionItem?.actionLabel)+" "),_c('v-divider',{staticClass:"my-1"}),_c('p',{staticStyle:{"max-width":"200px","text-wrap":"wrap"}},[_vm._v(" "+_vm._s(actionItem.comments)+" ")])],1)])}),1)],1):_vm._e(),(_vm.component.assignType === 'PRODUCT')?_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(proOrMat.isVisible || proOrMat.isVisibleAction),expression:"proOrMat.isVisible || proOrMat.isVisibleAction"}]},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[(_vm.currentPromosForSelectedBrand.length > 0)?_c('promosAnswerTable',{attrs:{"currentPromosForSelectedBrand":_vm.getCurrentPromosForSelectedBrandProOrMat(
                _vm.currentPromosForSelectedBrand,
                _vm.component,
                proOrMat
              ),"promosTypeList":_vm.promosTypeList,"placeTypeList":_vm.placeTypeList,"folletoTypeList":_vm.folletoTypeList,"component":_vm.component,"proOrMat":proOrMat},on:{"changeAnswer":(changeKey, record) => _vm.persistPromosAnswer(changeKey, record)}}):_vm._e()],1)],1):_vm._e(),(
          Object.keys(_vm.component.proOrMatList).pop() !== proOrMatKey &&
            (proOrMat.isVisible || proOrMat.isVisibleAction)
        )?_c('v-divider',{staticClass:"survey_pro_divider"}):_vm._e()],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }