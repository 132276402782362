<template>
  <div>
    <input
      :class="
        `survey-input-xs survey-input-number-xs ${this.importedDataStyling}`
      "
      v-model="controlValue"
      @change="onChangeInput"
      @keydown="onlyForCurrency"
      type="number"
      placeholder="0.00"
      required
      name="price"
      min="0"
      value="0"
      step="any"
      :readonly="readOnlyInputs"
    />
    <kpiObjectiveSpan
      :isKPIthisQuestion="isKPIthisQuestion"
      :foundObjectiveValue="foundObjectiveValue"
    />
  </div>
</template>

<script>
import kpiObjectiveSpan from "./kpiObjectiveSpan";

export default {
  props: [
    "value",
    "label",
    "readOnlyInputs",
    "isKPIthisQuestion",
    "foundObjectiveValue",
    "importedDataStyling"
  ],
  components: {
    kpiObjectiveSpan
  },
  data: function() {
    return {
      controlValue: this.value
    };
  },
  watch: {
    value(newVal, oldVal) {
      this.controlValue = newVal;
    }
  },
  methods: {
    onlyForCurrency($event) {
      let pvp = this.value;
      if (typeof pvp === "number") pvp = pvp.toString();

      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // // only allow number and one dot
      // if (
      //   (keyCode < 48 || keyCode > 57) &&
      //   (keyCode !== 46 || pvp.indexOf(".") !== -1)
      // ) {
      //   // 46 is dot
      //   $event.preventDefault();
      // }
      if (event.keyCode === 38 || event.keyCode === 40) {
        event.preventDefault();
      }
      // // restrict to 2 decimal places
      // if (
      //   pvp != null &&
      //   pvp.indexOf(".") > -1 &&
      //   pvp.split(".")[1].length > 1
      // ) {
      //   $event.preventDefault();
      // }
    },
    onChangeInput() {
      this.$emit("input", this.controlValue);
    }
  }
};
</script>

<style>
input.survey-input-number-xs::-webkit-inner-spin-button,
input.survey-input-number-xs::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  pointer-events: none;
}
</style>
