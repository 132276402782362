var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.isLoadingSpinner,"opacity":`0.5`,"zIndex":"99999"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"70","width":"7","color":"primary"}})],1),_c('modal',{staticClass:"photo_modal",attrs:{"name":`modal-photo-detail_${_vm.currentProOrMatId}_${_vm.question?.id}`,"width":`90%`,"height":`auto`,"scrollable":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-card',[_c('v-card-text',{staticClass:"objective_content"},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.modalTitle))])]),_c('v-card-text',[_c('div',{staticClass:"mt-2 text--primary"}),(_vm.isLoading)?_c('b-alert',{staticClass:"mt-2",attrs:{"show":"","variant":"info"}},[_c('b-icon',{attrs:{"icon":"arrow-counterclockwise","animation":"spin-reverse"}}),_vm._v(" cargando datos... ")],1):[_c('v-row',{staticClass:"portal-card-slider"},[(_vm.movePrevVisitSurveyResponse)?_c('b-icon',{staticClass:"my-1 btn-portal-card-slider portal-card-slider-prev",attrs:{"variant":"primary","icon":"caret-left-fill","font-scale":"3"},on:{"click":function($event){return _vm.slide('left')}}}):_vm._e(),(_vm.lastVisitCredential && _vm.last_survey_response)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"12"}},[_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentLastSurveyIndex > 0),expression:"currentLastSurveyIndex > 0"}],staticClass:"my-1 btn-portal-move-pos",attrs:{"variant":"primary","icon":"caret-up-fill","font-scale":"3"},on:{"click":function($event){$event.preventDefault();return _vm.prevSlide('LAST_SURVEY')}}})],1)],1),(this.lastWorksessionSurveyResponses.length > 0)?_c('div',{staticClass:"survey_response_slider"},[_c('div',{staticClass:"survey_response_slides",style:({
                      transform: `translateX(-${_vm.currentLastSurveyIndex *
                        100}%)`
                    })},_vm._l((this
                        .lastWorksessionSurveyResponses),function(last_survey_response_item,last_key){return _c('div',{key:`last_survey_response_item_${last_key}`,staticClass:"survey_response_slide_item"},[_c('div',[_c('ImageSlider',{attrs:{"isTodayVisit":false,"surveyResponseItem":last_survey_response_item,"currentVisitCredential":_vm.lastVisitCredential,"worksessionSurveyPhotosPortal":last_survey_response_item?.worksession_survey_photos_portal,"question":_vm.question,"attachments":last_survey_response_item?.worksession_survey_response_attachments}}),_c('PortalDetail',{attrs:{"survey_response_item":last_survey_response_item}})],1)])}),0)]):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"12"}},[_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(
                        _vm.currentLastSurveyIndex <
                          this.lastWorksessionSurveyResponses.length - 1
                      ),expression:"\n                        currentLastSurveyIndex <\n                          this.lastWorksessionSurveyResponses.length - 1\n                      "}],staticClass:"my-1 btn-portal-move-pos",attrs:{"variant":"primary","icon":"caret-down-fill","font-scale":"3"},on:{"click":function($event){$event.preventDefault();return _vm.nextSlide('LAST_SURVEY')}}})],1)],1)],1):_vm._e(),(
                  (_vm.currentVisitCredential && _vm.current_survey_response) ||
                    _vm.isTodayVisit
                )?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"12"}},[_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentSurveyIndex > 0),expression:"currentSurveyIndex > 0"}],staticClass:"my-1 btn-portal-move-pos",attrs:{"variant":"primary","icon":"caret-up-fill","font-scale":"3"},on:{"click":function($event){$event.preventDefault();return _vm.prevSlide('CURRENT_SURVEY')}}})],1)],1),_c('div',{staticClass:"survey_response_slider"},[_c('div',{staticClass:"survey_response_slides",style:({
                      transform: `translateX(-${_vm.currentSurveyIndex * 100}%)`
                    })},[_c('div',{staticClass:"survey_response_slide_item"},[_c('div',[_c('ImageSlider',{attrs:{"isTodayVisit":_vm.isTodayVisit,"surveyResponseItem":_vm.current_survey_response,"currentVisitCredential":_vm.currentVisitCredential,"worksessionSurveyPhotosPortal":_vm.worksessionSurveyPhotosPortal,"question":_vm.question,"attachments":_vm.current_attachments},on:{"deleteAttachmentItem":delId => _vm.deleteAttachmentItem(delId),"onUploadAttachment":() => _vm.onClickUploadAttachment()}}),_c('div',{staticClass:"mt-2 text--primary"},[_c('v-row',[(_vm.saveData)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"4"}},[_c('h6',{staticClass:"my-2 text-center"},[_vm._v("GPV")]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-rating',{attrs:{"background-color":"pink lighten-3","color":"pink","dense":"","size":"28","readonly":!_vm.isTodayVisit},model:{value:(_vm.saveData.scoreGPV),callback:function ($$v) {_vm.$set(_vm.saveData, "scoreGPV", $$v)},expression:"saveData.scoreGPV"}})],1),_c('v-textarea',{class:`gpv_comment_text ${
                                    !_vm.isTodayVisit ? 'readonly' : ''
                                  }`,attrs:{"label":_vm.$t('comments'),"hide-details":"","readonly":!_vm.isTodayVisit},model:{value:(_vm.saveData.commentsGPV),callback:function ($$v) {_vm.$set(_vm.saveData, "commentsGPV", $$v)},expression:"saveData.commentsGPV"}})],1):_vm._e(),(this.worksessionSurveyPhotosPortal)?_c('v-col',{attrs:{"cols":"12","lg":"4","md":"12","sm":"12"}},[_c('h6',{staticClass:"my-2 text-center"},[_vm._v(" "+_vm._s(_vm.$t("salesforce.Parent"))+" ")]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-rating',{attrs:{"background-color":"pink lighten-3","color":"pink","dense":"","size":"28","readonly":true},model:{value:(
                                    this.worksessionSurveyPhotosPortal
                                      .scoreBrand
                                  ),callback:function ($$v) {_vm.$set(this.worksessionSurveyPhotosPortal
                                      , "scoreBrand", $$v)},expression:"\n                                    this.worksessionSurveyPhotosPortal\n                                      .scoreBrand\n                                  "}})],1),_c('v-textarea',{class:'gpv_comment_text readonly',attrs:{"label":_vm.$t('comments'),"hide-details":"","readonly":true},model:{value:(
                                  this.worksessionSurveyPhotosPortal
                                    .commentsBrand
                                ),callback:function ($$v) {_vm.$set(this.worksessionSurveyPhotosPortal
                                    , "commentsBrand", $$v)},expression:"\n                                  this.worksessionSurveyPhotosPortal\n                                    .commentsBrand\n                                "}})],1):_vm._e(),(this.worksessionSurveyPhotosPortal)?_c('v-col',{attrs:{"cols":"12","lg":"4","md":"12","sm":"12"}},[_c('h6',{staticClass:"my-2 text-center"},[_vm._v(" "+_vm._s(_vm.$t("brands.brand"))+" ")]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-rating',{attrs:{"background-color":"pink lighten-3","color":"pink","dense":"","size":"28","readonly":true},model:{value:(
                                    this.worksessionSurveyPhotosPortal
                                      .scoreAdmin
                                  ),callback:function ($$v) {_vm.$set(this.worksessionSurveyPhotosPortal
                                      , "scoreAdmin", $$v)},expression:"\n                                    this.worksessionSurveyPhotosPortal\n                                      .scoreAdmin\n                                  "}})],1),_c('v-textarea',{class:'gpv_comment_text readonly',attrs:{"label":_vm.$t('comments'),"hide-details":"","readonly":true},model:{value:(
                                  this.worksessionSurveyPhotosPortal
                                    .commentsAdmin
                                ),callback:function ($$v) {_vm.$set(this.worksessionSurveyPhotosPortal
                                    , "commentsAdmin", $$v)},expression:"\n                                  this.worksessionSurveyPhotosPortal\n                                    .commentsAdmin\n                                "}})],1):_vm._e()],1)],1)],1)]),_vm._l((this
                        .currentWorksessionOtherSurveyResponses),function(current_survey_response_item,current_key){return _c('div',{key:`current_survey_response_item_${current_key}`,staticClass:"survey_response_slide_item"},[_c('div',[_c('ImageSlider',{attrs:{"isTodayVisit":_vm.isTodayVisit,"surveyResponseItem":current_survey_response_item,"currentVisitCredential":_vm.currentVisitCredential,"worksessionSurveyPhotosPortal":current_survey_response_item?.worksession_survey_photos_portal,"question":_vm.question,"attachments":current_survey_response_item?.worksession_survey_response_attachments}}),_c('PortalDetail',{attrs:{"survey_response_item":current_survey_response_item}})],1)])})],2)]),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"12"}},[_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(
                        _vm.currentSurveyIndex <
                          this.currentWorksessionOtherSurveyResponses.length
                      ),expression:"\n                        currentSurveyIndex <\n                          this.currentWorksessionOtherSurveyResponses.length\n                      "}],staticClass:"my-1 btn-portal-move-pos",attrs:{"variant":"primary","icon":"caret-down-fill","font-scale":"3"},on:{"click":function($event){$event.preventDefault();return _vm.nextSlide('CURRENT_SURVEY')}}})],1)],1)],1):_vm._e(),(_vm.moveNextVisitSurveyResponse)?_c('b-icon',{staticClass:"my-1 btn-portal-card-slider portal-card-slider-next",attrs:{"variant":"primary","icon":"caret-right-fill","font-scale":"3"},on:{"click":function($event){return _vm.slide('right')}}}):_vm._e()],1)]],2),_c('v-card-actions',[_c('v-spacer'),(_vm.isTodayVisit)?_c('v-btn',{attrs:{"color":"blue darken-1"},on:{"click":_vm.onClickSaveComments}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")]):_vm._e(),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.hideModal()}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }