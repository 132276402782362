<template>
  <div>
    <modal :name="`${this.modalref}`" :width="`90%`" :height="`auto`" scrollable>
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text class="text--primary" style="height: 80vh">
            <object
              v-if="pdfFilePath"
              :data="`${pdfFilePath}`"
              type="application/pdf"
              width="100%"
              height="100%"
            >
              <p>
                Your web browser doesn't have a PDF plugin. Instead you can
                <a :href="`${pdfFilePath}`"
                  >click here to download the PDF file.</a
                >
              </p>
            </object>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="hideModal">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";

export default {
  name: "DialogPDF",
  props: ["value", "modalref"],
  components: {},
  data: function() {
    return {
      pdfFilePrefix:
        process.env.VUE_APP_ENV !== "development"
          ? "https://docs.google.com/gview?embedded=true&url="
          : // ? "https://docs.google.com/viewerng/viewer?url="
            "",
      pdfFilePath: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    showModal(pdfPath) {
      this.init(pdfPath);
      this.$modal.show(this.modalref);
    },
    hideModal() {
      this.$modal.hide(this.modalref);
    },
    async init(pdfPath) {
      this.pdfFilePath = this.pdfFilePrefix + pdfPath;
    },
  },
  async updated() {},
  async mounted() {},
};
</script>
