var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"survey-table-container"},[_c('table',{staticClass:"table b-table table-striped table-bordered table-sm b-table-stacked-sm survey_answers_tb"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("Product")))]),_vm._l((_vm.getQuestionList(
            _vm.component
          )),function(col_question_item,col_question_key){return [_c('th',{key:`col_question_key_${col_question_key}`},[_vm._v(" "+_vm._s(col_question_item?.name)+" ")])]})],2)]),_c('tbody',[_vm._l((_vm.component.proOrMatList),function(proOrMat,proOrMatKey){return [_c('tr',{directives:[{name:"show",rawName:"v-show",value:(proOrMat.isVisible || proOrMat.isVisibleAction),expression:"proOrMat.isVisible || proOrMat.isVisibleAction"}],key:`component_promat_${proOrMatKey}`},[_c('td',{staticStyle:{"min-width":"200px"}},[_c('div',{staticClass:"d-flex flex-column"},[(proOrMat)?_c('h5',[_vm._v(" "+_vm._s(proOrMat.name)+" ")]):_vm._e(),(
                  _vm.getProOrMatImageComponent(
                    _vm.component.assignType,
                    proOrMat,
                    true
                  )
                )?_c('img',{staticStyle:{"border":"1px solid #aaa","max-width":"100%","cursor":"pointer"},attrs:{"src":_vm.getProOrMatImageComponent(
                    _vm.component.assignType,
                    proOrMat,
                    true
                  ),"alt":""},on:{"click":function($event){return _vm.onItemShowImageDialog(_vm.component.assignType, proOrMat, false)}}}):_vm._e(),(proOrMat.alertList.length > 0)?_c('b-button',{staticClass:"ml-2 btn_color_white",attrs:{"size":"sm","variant":proOrMat.isReadProductAlert ? `success` : `danger`},on:{"click":function($event){return _vm.onClickProductAlert(
                    proOrMat.alertList,
                    _vm.surveyListItem,
                    _vm.component,
                    proOrMat
                  )}}},[_c('b-icon',{attrs:{"icon":"alarm-fill"}})],1):_vm._e(),(
                  _vm.component.isAction &&
                    Object.values(proOrMat?.actions).length === 0
                )?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(proOrMat.isVisible || proOrMat.isVisibleAction),expression:"proOrMat.isVisible || proOrMat.isVisibleAction"}],staticClass:"mt-1",staticStyle:{"color":"#fff !important"},attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.onClickUpsertActions(
                    _vm.surveyListItem,
                    _vm.component,
                    proOrMat,
                    null,
                    null
                  )}}},[_c('b-icon',{attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.$t("Add Action"))+" ")],1):_vm._e()],1)]),_vm._l((_vm.getQuestionList(_vm.component)),function(col_question_item){return [_vm._l(([
                _vm.getQuestionItem(proOrMat.questions, col_question_item.id),
              ]),function(question){return [_c('td',{key:`component_proOrMat_${_vm.surveyListKey}_${_vm.comKey}_${proOrMatKey}_question_${question.id}`},[_c('SurveyQuestion',{directives:[{name:"show",rawName:"v-show",value:(question.isVisible && question.isCondVisible),expression:"question.isVisible && question.isCondVisible"}],ref:`survey_question_ref_${proOrMatKey}_${question.id}`,refInFor:true,attrs:{"detectedSurveyChainIdForPuncDetails":_vm.detectedSurveyChainIdForPuncDetails,"worksession_survey_response_approval":question.worksession_survey_response_approval,"optionScores":question.optionScores,"initialValue":question.value,"currentScore":question.score,"maximumScore":question.maximumScore,"question":question},on:{"onClickCompQueImage":(image) => _vm.onClickCompQueImage(image),"onClickCorrectionAlert":(worksession_survey_response_approval) =>
                      _vm.onClickCorrectionAlert(
                        worksession_survey_response_approval
                      ),"input":(result, typeStr) =>
                      _vm.persistQuestionValue(
                        result,
                        typeStr,
                        _vm.surveyListItem.id,
                        _vm.component.id,
                        proOrMat?.id ? proOrMat.id : null,
                        question.id,
                        _vm.component.assignType,
                        question,
                        `survey_question_ref_${proOrMatKey}_${question.id}`,
                        question.isCondVisible
                      )}})],1)]})]})],2),(
            _vm.component.isAction && Object.values(proOrMat?.actions).length > 0
          )?_c('tr',{key:`component_promat_action_${proOrMatKey}`},[_c('td',[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(proOrMat.isVisible || proOrMat.isVisibleAction),expression:"proOrMat.isVisible || proOrMat.isVisibleAction"}],staticClass:"mr-1",staticStyle:{"color":"#fff !important"},attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.onClickUpsertActions(
                  _vm.surveyListItem,
                  _vm.component,
                  proOrMat,
                  null,
                  null
                )}}},[_c('b-icon',{attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.$t("Add Action"))+" ")],1)],1),_c('td',{attrs:{"colspan":"100%"}},_vm._l((proOrMat?.actions),function(actionItem,actionKey){return _c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(actionItem.isVisible),expression:"actionItem.isVisible"}],key:`add_action_item_${actionKey}`,class:`m-2 added_action_chip ${
                  actionItem.actionType === 'PROMOLOCAL'
                    ? 'chip_promo_local'
                    : ''
                }`,attrs:{"close":""},on:{"click:close":function($event){return _vm.onClickRemoveAction(
                  _vm.surveyListItem,
                  _vm.component,
                  proOrMat,
                  actionKey
                )}}},[_c('div',{staticClass:"px-2 py-2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onClickUpsertActions(
                    _vm.surveyListItem,
                    _vm.component,
                    proOrMat,
                    actionItem,
                    actionKey
                  )}}},[_vm._v(" "+_vm._s(actionItem?.actionLabel)+" "),_c('v-divider',{staticClass:"my-1"}),_c('p',{staticStyle:{"max-width":"200px","text-wrap":"wrap"}},[_vm._v(" "+_vm._s(actionItem.comments)+" ")])],1)])}),1)]):_vm._e(),(_vm.component.assignType === 'PRODUCT')?_c('tr',{directives:[{name:"show",rawName:"v-show",value:(proOrMat.isVisible || proOrMat.isVisibleAction),expression:"proOrMat.isVisible || proOrMat.isVisibleAction"}],key:`component_promat_promosanswer_${proOrMatKey}`},[_c('td',{attrs:{"colspan":"100%"}},[(_vm.currentPromosForSelectedBrand.length > 0)?_c('promosAnswerTable',{attrs:{"currentPromosForSelectedBrand":_vm.getCurrentPromosForSelectedBrandProOrMat(
                  _vm.currentPromosForSelectedBrand,
                  _vm.component,
                  proOrMat
                ),"promosTypeList":_vm.promosTypeList,"placeTypeList":_vm.placeTypeList,"folletoTypeList":_vm.folletoTypeList,"component":_vm.component,"proOrMat":proOrMat},on:{"changeAnswer":(changeKey, record) => _vm.persistPromosAnswer(changeKey, record)}}):_vm._e()],1)]):_vm._e()]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }