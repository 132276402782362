<template>
  <div>
    <modal name="modal-onepage" :width="`90%`" :height="`auto`" scrollable>
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-title>
            <div class="d-flex justify-content-start align-center">
              <span class="text-h5 mr-3">One Page: </span>
              <v-select
                v-if="this.brandList.length > 1"
                v-model="selectedBrands"
                class="select_onepage_brandlist"
                :items="this.brandList"
                item-text="name"
                item-value="id"
                :menu-props="{ maxHeight: '400' }"
                label="Brand"
                multiple
                chips
                @change="onChangeSelectedBrands"
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item
                    v-on="on"
                    v-bind="attrs"
                    #default="{ active }"
                    :class="[{ grey_zoned_brand_item: !item.isIncluded }]"
                  >
                    <v-list-item-action>
                      <v-checkbox
                        :ripple="false"
                        :input-value="active"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row no-gutters align="center">
                          {{ item.name }}
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </div>
          </v-card-title>
          <v-divider class="mx-4 my-0"></v-divider>

          <v-card-text class="text--primary">
            <template v-for="(brandItem, bKey) in brandList">
              <v-row
                :key="`brandItem_${bKey}`"
                v-show="
                  selectedBrands.length === 0 ||
                    (selectedBrands.length > 0 &&
                      selectedBrands.includes(brandItem.id))
                "
              >
                <v-col cols="12" sm="12" md="12">
                  <h5>Brand Name: {{ brandItem.name }}</h5>
                  <template v-for="(pdfItem, pdfKey) in brandItem.pdfContents">
                    <p :key="`pdfItem_${pdfKey}`">
                      PDF Description: {{ pdfItem.description }}
                    </p>

                    <div
                      :key="`pdf_contents_${pdfKey}`"
                      class="pdf_contents"
                      style="height: 80vh"
                      v-if="pdfItem.onepagePdfFile && pdfItem.pdfPath"
                    >
                      <embed
                        :src="pdfItem.pdfFileUri"
                        width="100%"
                        height="100%"
                        type="application/pdf"
                      />
                    </div>
                  </template>
                </v-col>
              </v-row>
            </template>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="hideModal">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";

export default {
  name: "DialogOnePage",
  props: ["value", "routeId", "posId", "worksessionPosBrands"],
  components: {},
  data: function() {
    return {
      current_row: null,
      brandList: [],
      selectedBrands: [],
      pdfFilePrefix:
        process.env.VUE_APP_ENV !== "development"
          ? "https://docs.google.com/gview?embedded=true&url="
          : // ? "https://docs.google.com/viewerng/viewer?url="
            ""
    };
  },
  watch: {},
  computed: {},
  methods: {
    showModal() {
      this.init();
      this.$modal.show("modal-onepage");
    },
    hideModal() {
      this.$modal.hide("modal-onepage");
    },
    onChangeSelectedBrands() {
      console.log(this.selectedBrands);
    },
    async init() {
      this.selectedBrands = [];
      let brandIdListForThisPos = this.worksessionPosBrands.map(el => el.id);
      let brandListWithPDF = [];
      let brandListNotIncluded = [];
      let curDate = new Date().toISOString().slice(0, 10);
      this.worksessionPosBrands.map(el => {
        let push_item = { ...el };
        push_item["pdfContents"] = [];
        push_item["isIncluded"] = false;
        if (push_item?.brand_onepages) {
          push_item["pdfContents"] = push_item.brand_onepages.map(oneEl => {
            let onepageItem = {
              ...oneEl
            };
            if (oneEl?.onepagePdfFile?.file) {
              const pdfFileUri = ApiService.getFileUri(oneEl.onepagePdfFile);
              onepageItem["pdfFileUri"] = pdfFileUri;
              onepageItem["pdfPath"] = this.pdfFilePrefix + pdfFileUri;
            }
            if (
              oneEl.startDate <= curDate &&
              curDate <= oneEl.endDate &&
              oneEl.status === "active"
            ) {
              push_item["isIncluded"] = true;
            }
            return onepageItem;
          });
        }
        if (push_item["isIncluded"]) {
          brandListWithPDF.push(push_item);
        } else {
          brandListNotIncluded.push(push_item);
        }
        return push_item;
      });
      console.log("AAAAAAAAAAAAA - ", brandListWithPDF);
      this.brandList = [...brandListWithPDF, ...brandListNotIncluded];
      console.log("CCCCCCCC - ", this.brandList);
    }
  },
  async updated() {},
  async mounted() {}
};
</script>

<style>
.v-list > .v-list-item.grey_zoned_brand_item {
  background: #8f8f8f !important;
}
</style>
