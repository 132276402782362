<template>
  <div v-if="currentPromosForSelectedBrand.length > 0">
    <DialogPDF
      :ref="
        `dialogAnswerPDF_${component.id}_${proOrMat?.id ? proOrMat.id : 'null'}`
      "
      :modalref="
        `dialogAnswerPDF_${component.id}_${proOrMat?.id ? proOrMat.id : 'null'}`
      "
    />
    <v-layout v-resize="onResize" column>
      <v-data-table
        :headers="promosHeaders"
        :items="currentPromosForSelectedBrand"
        class="elevation-1"
        :items-per-page="100"
        :footer-props="{
          'items-per-page-options': [10, 50, 100]
        }"
        :hide-default-footer="true"
        disable-pagination
        :item-class="row_classes"
        :mobile-breakpoint="700"
      >
        <template v-slot:item.date="{ item }">
          <span v-if="!item.isAnswer">
            {{ `${item.startDate}/${item.endDate}` }}
          </span>
        </template>
        <template v-slot:item.orderDate="{ item }">
          <span v-if="!item.isAnswer">
            {{ `${item.orderDateLabel}` }}
          </span>
        </template>
        <template v-slot:item.product="{ item }"
          >{{ item.productsLabel }}
        </template>
        <template v-slot:item.material="{ item }"
          >{{ item.material?.name }}
        </template>
        <template v-slot:item.promosType="{ item }">
          <span v-if="!item.isAnswer">{{
            promosTypeList[item.promosType]
          }}</span>
          <v-autocomplete
            v-else
            v-bind:label="`${$t('Type')} Promo`"
            :items="selectPromosTypeList"
            item-text="label"
            item-value="value"
            filled
            clearable
            dense
            hide-no-data
            hide-details
            v-model="item.promosTypeValue"
            @change="onChangeAnswerValue('promosTypeValue', item)"
          ></v-autocomplete>
        </template>
        <template v-slot:item.place="{ item }">
          {{ placeTypeList[item.place] }}
          <span v-if="!item.isAnswer">{{ placeTypeList[item.place] }}</span>
          <v-autocomplete
            v-else
            v-bind:label="`${$t('Place')}`"
            :items="selectPlaceList"
            item-text="label"
            item-value="value"
            filled
            clearable
            dense
            hide-no-data
            hide-details
            v-model="item.placeValue"
            @change="onChangeAnswerValue('placeValue', item)"
          ></v-autocomplete>
        </template>
        <template v-slot:item.price="{ item }">
          <span v-if="!item.isAnswer">{{ item.price }}</span>
          <v-text-field
            v-else
            v-bind:label="$t('Price')"
            type="number"
            dense
            hide-no-data
            hide-details
            v-model="item.priceValue"
            @change="onChangeAnswerValue('priceValue', item)"
          ></v-text-field>
        </template>
        <template v-slot:item.folleto="{ item }">
          <span v-if="!item.isAnswer">{{ folletoTypeList[item.folleto] }}</span>
          <v-autocomplete
            v-else
            v-bind:label="`${$t('Folleto')}`"
            :items="selectFolletoTypeList"
            item-text="label"
            item-value="value"
            filled
            clearable
            dense
            hide-no-data
            hide-details
            v-model="item.folletoValue"
            @change="onChangeAnswerValue('folletoValue', item)"
          ></v-autocomplete>
        </template>
        <template v-slot:item.description="{ item }">
          <span v-if="!item.isAnswer">{{ item.description }}</span>
          <v-autocomplete
            v-else
            v-bind:label="`Presente`"
            :items="selectIsPresentTypeList"
            item-text="label"
            item-value="value"
            filled
            clearable
            dense
            hide-no-data
            hide-details
            v-model="item.isPresent"
            @change="onChangeAnswerValue('isPresent', item)"
          ></v-autocomplete>
        </template>
        <template v-slot:item.material="{ item }">
          <span v-if="!item.isAnswer">{{ item.material?.name }}</span>
          <v-autocomplete
            v-else
            v-bind:label="`${$t('Material')}`"
            :items="selectFolletoTypeList"
            item-text="label"
            item-value="value"
            filled
            clearable
            dense
            hide-no-data
            hide-details
            v-model="item.materialValue"
            @change="onChangeAnswerValue('materialValue', item)"
          ></v-autocomplete>
        </template>
        <template v-slot:item.status="{ item }">
          <div v-if="!item.isAnswer">
            <v-chip :color="getColor(item.statusKey)" dark>
              {{ item.status }}
            </v-chip>
            <v-chip
              :color="`red`"
              @click="
                onClickPDF(
                  item,
                  `dialogAnswerPDF_${component.id}_${
                    proOrMat?.id ? proOrMat.id : 'null'
                  }`
                )
              "
              v-if="item?.promosPdfFile"
              dark
              class="ml-2"
            >
              PDF
            </v-chip>
          </div>
        </template>
      </v-data-table>
    </v-layout>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import DialogPDF from "../DialogPDF.vue";

export default {
  name: "promosAnswerTable",
  props: [
    "currentPromosForSelectedBrand",
    "promosTypeList",
    "placeTypeList",
    "folletoTypeList",
    "isPresentTypeList",
    "component",
    "proOrMat"
  ],
  components: {
    DialogPDF
  },
  data: function() {
    return {
      isMobile: false,
      shouldCheckFullFilledCols: ["price", "place", "folleto"]
    };
  },
  watch: {},
  computed: {
    selectPromosTypeList() {
      let values = [{ label: "Not Implemented", value: "Not Implemented" }];
      for (const [key, value] of Object.entries(this.promosTypeList)) {
        let pushItem = {
          label: value,
          value: key
        };
        values.push(pushItem);
      }
      return values;
    },
    selectPlaceList() {
      let values = [{ label: "Not Implemented", value: "Not Implemented" }];
      for (const [key, value] of Object.entries(this.placeTypeList)) {
        let pushItem = {
          label: value,
          value: key
        };
        values.push(pushItem);
      }
      return values;
    },
    selectFolletoTypeList() {
      let values = [{ label: "Not Implemented", value: "Not Implemented" }];
      for (const [key, value] of Object.entries(this.folletoTypeList)) {
        let pushItem = {
          label: value,
          value: key
        };
        values.push(pushItem);
      }
      return values;
    },
    selectIsPresentTypeList() {
      let values = [];
      for (const [key, value] of Object.entries(this.isPresentTypeList)) {
        let pushItem = {
          label: value,
          value: key
        };
        values.push(pushItem);
      }
      return values;
    },
    checkIsFullFilledFiveFields() {
      let isFullFilled = true;
      // price, place, CM(space), material, folleto
      if (
        this.currentPromosForSelectedBrand &&
        this.currentPromosForSelectedBrand.length > 0
      ) {
        for (const row of this.currentPromosForSelectedBrand) {
          for (const checkKey of this.shouldCheckFullFilledCols) {
            if (Object.keys(row).includes(checkKey) && row[checkKey] === null) {
              isFullFilled = false;
            }
          }
        }
      }
      return isFullFilled;
    },
    promosHeaders() {
      let columns = [
        {
          text: `${this.$t("brands.description")}`,
          value: "description",
          width: 170
        },
        {
          text: `${this.$t("Date")} ${this.$t("consumer")}`,
          value: "dateLabel",
          width: 160
        }
      ];
      let firstFullFillRequiredCols = [
        {
          text: `${this.$t("orderDate")}`,
          value: "orderDateLabel",
          width: 160
        }
      ];
      if (this.checkRequiredFields(["orderDate"])) {
        columns = [...columns, ...firstFullFillRequiredCols];
      }
      columns = [
        ...columns,
        ...[
          {
            text: `${this.$t("Product")}`,
            value: "productsLabel",
            width: 160
          }
        ]
      ];
      if (this.checkFullfilledColumns("material")) {
        columns = [
          ...columns,
          {
            text: `${this.$t("Material")}`,
            value: "material",
            width: 160
          }
        ];
      }
      let secondFullFillRequiredCols = [
        {
          text: `${this.$t("Type")} Promo`,
          value: "promosType",
          width: 120
        },
        {
          text: `${this.$t("Price")}`,
          value: "price",
          width: 120
        },
        {
          text: `${this.$t("Place")}`,
          value: "place",
          width: 120
        },
        // {
        //   text: `CM`,
        //   value: "space",
        //   // width: 150,
        // },
        {
          text: `${this.$t("Folleto")}`,
          value: "folleto",
          width: 120
        }
      ];
      if (
        this.checkRequiredFields(["promosType", "price", "place", "folleto"])
      ) {
        columns = [...columns, ...secondFullFillRequiredCols];
      }
      columns = [
        ...columns,
        {
          text: `${this.$t("salesforce.status")}`,
          value: "status",
          width: 170
        }
      ];
      return columns;
    }
  },
  methods: {
    checkRequiredFields(fullFillRequiredCols) {
      let isFullFilled = true;
      if (
        this.currentPromosForSelectedBrand &&
        this.currentPromosForSelectedBrand.length > 0
      ) {
        for (const row of this.currentPromosForSelectedBrand) {
          for (const checkKey of fullFillRequiredCols) {
            if (
              checkKey === "orderDate" &&
              Object.keys(row).includes("orderDateLabel")
            ) {
              if (!row?.startOrderDate || !row?.endOrderDate) {
                isFullFilled = false;
              }
            } else if (
              Object.keys(row).includes(checkKey) &&
              row[checkKey] === null
            ) {
              isFullFilled = false;
            }
          }
        }
      }
      return isFullFilled;
    },
    checkFullfilledColumns(fullFillRequiredCol) {
      let isFullFilled = false;
      if (
        this.currentPromosForSelectedBrand &&
        this.currentPromosForSelectedBrand.length > 0
      ) {
        const recordCount = this.currentPromosForSelectedBrand.filter(
          el => !el?.isAnswer
        ).length;
        let notFullFilledRecordCount = 0;
        for (const row of this.currentPromosForSelectedBrand) {
          if (row?.isAnswer) continue;
          if (
            fullFillRequiredCol === "orderDate" &&
            Object.keys(row).includes("orderDateLabel")
          ) {
            if (!row?.startOrderDate || !row?.endOrderDate) {
              notFullFilledRecordCount++;
            }
          } else if (
            Object.keys(row).includes(fullFillRequiredCol) &&
            (row[fullFillRequiredCol] === null ||
              row[fullFillRequiredCol] === "")
          ) {
            notFullFilledRecordCount++;
          }
        }
        if (notFullFilledRecordCount < recordCount) {
          isFullFilled = true;
        }
      }
      return isFullFilled;
    },
    onChangeAnswerValue(changeKey, item) {
      console.log(changeKey, item);
      this.$emit("changeAnswer", changeKey, item);
    },
    onResize() {
      if (window.innerWidth < 1000) this.isMobile = true;
      else this.isMobile = false;
    },
    row_classes(item) {
      if (item.statusKey === "PREPARE") {
        return "bg_orange";
      }
    },
    getColor(status) {
      if (status === "ACTIVE" || status === "PREPARE") {
        return "green";
      } else if (status === "PREPARE") {
        return "orange";
      }
      return "red";
    },
    onClickPDF(item, referenceId) {
      if (item?.promosPdfFile) {
        let filePath = ApiService.getFileUri(item?.promosPdfFile);
        this.$refs[referenceId].showModal(filePath);
      } else {
        logError("No hay datos para mostrar.");
      }
    },
    async init() {}
  },
  async updated() {},
  async mounted() {}
};
</script>
<style>
/* each row block css in mobile*/
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  margin: 10px;
  border: 1px solid #ededed;
  display: block;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  word-break: break-all;
  padding-top: 4px;
  padding-bottom: 4px;
}
</style>
