<template>
  <div class="survey-table-container">
    <table
      class="table b-table table-striped table-bordered table-sm b-table-stacked-sm survey_answers_tb"
    >
      <thead>
        <tr>
          <th>{{ $t("Product") }}</th>
          <template
            v-for="(col_question_item, col_question_key) in getQuestionList(
              component
            )"
          >
            <th :key="`col_question_key_${col_question_key}`">
              {{ col_question_item?.name }}
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="(proOrMat, proOrMatKey) in component.proOrMatList"
          v-show="proOrMat.isVisible || proOrMat.isVisibleAction"
        >
          <tr
            :key="`component_promat_${proOrMatKey}`"
            v-show="proOrMat.isVisible || proOrMat.isVisibleAction"
          >
            <td style="min-width: 200px;">
              <div class="d-flex flex-column">
                <h5 v-if="proOrMat">
                  {{ proOrMat.name }}
                </h5>
                <img
                  v-if="
                    getProOrMatImageComponent(
                      component.assignType,
                      proOrMat,
                      true
                    )
                  "
                  style="border: 1px solid #aaa; max-width: 100%; cursor: pointer;"
                  :src="
                    getProOrMatImageComponent(
                      component.assignType,
                      proOrMat,
                      true
                    )
                  "
                  @click="
                    onItemShowImageDialog(component.assignType, proOrMat, false)
                  "
                  alt=""
                />
                <b-button
                  class="ml-2 btn_color_white"
                  size="sm"
                  v-if="proOrMat.alertList.length > 0"
                  :variant="proOrMat.isReadProductAlert ? `success` : `danger`"
                  @click="
                    onClickProductAlert(
                      proOrMat.alertList,
                      surveyListItem,
                      component,
                      proOrMat
                    )
                  "
                >
                  <b-icon icon="alarm-fill"></b-icon>
                </b-button>
                <b-button
                  v-if="
                    component.isAction &&
                      Object.values(proOrMat?.actions).length === 0
                  "
                  class="mt-1"
                  variant="success"
                  size="sm"
                  style="color: #fff !important;"
                  v-show="proOrMat.isVisible || proOrMat.isVisibleAction"
                  @click="
                    onClickUpsertActions(
                      surveyListItem,
                      component,
                      proOrMat,
                      null,
                      null
                    )
                  "
                >
                  <b-icon icon="plus"></b-icon>
                  {{ $t("Add Action") }}
                </b-button>
              </div>
            </td>
            <template v-for="col_question_item in getQuestionList(component)">
              <template
                v-for="question in [
                  getQuestionItem(proOrMat.questions, col_question_item.id),
                ]"
              >
                <td
                  :key="
                    `component_proOrMat_${surveyListKey}_${comKey}_${proOrMatKey}_question_${question.id}`
                  "
                >
                  <SurveyQuestion
                    v-show="question.isVisible && question.isCondVisible"
                    :detectedSurveyChainIdForPuncDetails="
                      detectedSurveyChainIdForPuncDetails
                    "
                    :worksession_survey_response_approval="
                      question.worksession_survey_response_approval
                    "
                    :optionScores="question.optionScores"
                    :initialValue="question.value"
                    :currentScore="question.score"
                    :maximumScore="question.maximumScore"
                    :question="question"
                    @onClickCompQueImage="(image) => onClickCompQueImage(image)"
                    @onClickCorrectionAlert="
                      (worksession_survey_response_approval) =>
                        onClickCorrectionAlert(
                          worksession_survey_response_approval
                        )
                    "
                    @input="
                      (result, typeStr) =>
                        persistQuestionValue(
                          result,
                          typeStr,
                          surveyListItem.id,
                          component.id,
                          proOrMat?.id ? proOrMat.id : null,
                          question.id,
                          component.assignType,
                          question,
                          `survey_question_ref_${proOrMatKey}_${question.id}`,
                          question.isCondVisible
                        )
                    "
                    :ref="`survey_question_ref_${proOrMatKey}_${question.id}`"
                  />
                </td>
              </template>
            </template>
          </tr>
          <tr
            :key="`component_promat_action_${proOrMatKey}`"
            v-if="
              component.isAction && Object.values(proOrMat?.actions).length > 0
            "
          >
            <td>
              <b-button
                class="mr-1"
                variant="success"
                size="sm"
                style="color: #fff !important;"
                v-show="proOrMat.isVisible || proOrMat.isVisibleAction"
                @click="
                  onClickUpsertActions(
                    surveyListItem,
                    component,
                    proOrMat,
                    null,
                    null
                  )
                "
              >
                <b-icon icon="plus"></b-icon>
                {{ $t("Add Action") }}
              </b-button>
            </td>
            <td colspan="100%">
              <v-chip
                :class="
                  `m-2 added_action_chip ${
                    actionItem.actionType === 'PROMOLOCAL'
                      ? 'chip_promo_local'
                      : ''
                  }`
                "
                v-for="(actionItem, actionKey) in proOrMat?.actions"
                v-show="actionItem.isVisible"
                v-bind:key="`add_action_item_${actionKey}`"
                close
                @click:close="
                  onClickRemoveAction(
                    surveyListItem,
                    component,
                    proOrMat,
                    actionKey
                  )
                "
              >
                <div
                  class="px-2 py-2"
                  style="cursor: pointer;"
                  @click="
                    onClickUpsertActions(
                      surveyListItem,
                      component,
                      proOrMat,
                      actionItem,
                      actionKey
                    )
                  "
                >
                  {{ actionItem?.actionLabel }}
                  <v-divider class="my-1"></v-divider>
                  <p style="max-width: 200px; text-wrap: wrap">
                    {{ actionItem.comments }}
                  </p>
                </div>
              </v-chip>
            </td>
          </tr>
          <tr
            :key="`component_promat_promosanswer_${proOrMatKey}`"
            v-show="proOrMat.isVisible || proOrMat.isVisibleAction"
            v-if="component.assignType === 'PRODUCT'"
          >
            <td colspan="100%">
              <promosAnswerTable
                v-if="currentPromosForSelectedBrand.length > 0"
                :currentPromosForSelectedBrand="
                  getCurrentPromosForSelectedBrandProOrMat(
                    currentPromosForSelectedBrand,
                    component,
                    proOrMat
                  )
                "
                :promosTypeList="promosTypeList"
                :placeTypeList="placeTypeList"
                :folletoTypeList="folletoTypeList"
                :component="component"
                :proOrMat="proOrMat"
                @changeAnswer="
                  (changeKey, record) => persistPromosAnswer(changeKey, record)
                "
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import SurveyQuestion from "./question";
import promosAnswerTable from "./promosAnswerTable.vue";

export default {
  name: "surveyTableView",

  props: [
    "QUESTION_TYPES",
    "surveyListItem",
    "component",
    "surveyListKey",
    "comKey",
    "currentPromosForSelectedBrand",
    "getQuestionItem",
    "getProOrMatImageComponent",
    "getCurrentPromosForSelectedBrandProOrMat",
    "detectedSurveyChainIdForPuncDetails",
    "onItemShowImageDialog",
    "onClickProductAlert",
    "onClickCompQueImage",
    "onClickCorrectionAlert",
    "persistQuestionValue",
    "onClickUpsertActions",
    "onClickRemoveAction",
    "persistPromosAnswer",
    "getQuestionList",
    "promosTypeList",
    "placeTypeList",
    "folletoTypeList",
  ],

  components: {
    SurveyQuestion,
    promosAnswerTable,
  },
};
</script>

<style>
.survey-table-container {
  overflow-x: scroll;
}
</style>
