import Cookies from "js-cookie";
import { logError } from "../utils";

const StorageService = {
  KEY_SHOW_TYPE_IN_SURVEY: "KEY_SHOW_TYPE_IN_SURVEY",

  initialize() {},

  getCookie(tokenKey) {
    const cookieValue = Cookies.get(tokenKey);
    if (cookieValue) {
      // Cookie found, do something with the value
      console.log("User Cookie:", cookieValue);
    } else {
      // Cookie not found
      console.log("User Cookie not found.");
    }
    return cookieValue;
  },

  setCookie(tokenKey, tokenValue, expiration) {
    let expiration_period = expiration;
    if (!expiration_period) expiration_period = 30;
    Cookies.set(tokenKey, tokenValue, { expires: expiration_period }); // Cookie will expire in 7 days
  },

  removeCookie(tokenKey) {
    Cookies.remove(tokenKey);
  },

  getToken(tokenKey) {
    return window.localStorage.getItem(tokenKey);
  },

  //use this to remove while testing: localStorage.removeItem('id_token');
  saveToken(tokenKey, token) {
    window.localStorage.setItem(tokenKey, token);
  },

  destroyToken(tokenKey) {
    window.localStorage.removeItem(tokenKey);
  }
};

export default StorageService;
