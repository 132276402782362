<template>
  <div class="mt-4 text--primary">
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="4">
        <h6 class="my-2 text-center">GPV</h6>
        <div class="d-flex justify-content-center">
          <v-rating
            background-color="pink lighten-3"
            color="pink"
            dense
            size="28"
            v-model="
              survey_response_item.worksession_survey_photos_portal.scoreGPV
            "
            :readonly="true"
          ></v-rating>
        </div>
        <v-textarea
          :label="$t('comments')"
          hide-details
          v-model="
            survey_response_item.worksession_survey_photos_portal.commentsGPV
          "
          :readonly="true"
          v-bind:class="'gpv_comment_text readonly'"
        ></v-textarea>
      </v-col>
      <v-col
        cols="12"
        lg="4"
        md="12"
        sm="12"
        v-if="survey_response_item?.worksession_survey_photos_portal"
      >
        <h6 class="my-2 text-center">
          {{ $t("salesforce.Parent") }}
        </h6>
        <div class="d-flex justify-content-center">
          <v-rating
            background-color="pink lighten-3"
            color="pink"
            dense
            size="28"
            v-model="
              survey_response_item.worksession_survey_photos_portal.scoreBrand
            "
            :readonly="true"
          ></v-rating>
        </div>
        <v-textarea
          :label="$t('comments')"
          hide-details
          v-model="
            survey_response_item.worksession_survey_photos_portal.commentsBrand
          "
          :readonly="true"
          v-bind:class="'gpv_comment_text readonly'"
        ></v-textarea>
      </v-col>
      <v-col
        cols="12"
        lg="4"
        md="12"
        sm="12"
        v-if="survey_response_item?.worksession_survey_photos_portal"
      >
        <h6 class="my-2 text-center">
          {{ $t("brands.brand") }}
        </h6>
        <div class="d-flex justify-content-center">
          <v-rating
            background-color="pink lighten-3"
            color="pink"
            dense
            size="28"
            v-model="
              survey_response_item.worksession_survey_photos_portal.scoreAdmin
            "
            :readonly="true"
          ></v-rating>
        </div>
        <v-textarea
          :label="$t('comments')"
          hide-details
          v-model="
            survey_response_item.worksession_survey_photos_portal.commentsAdmin
          "
          :readonly="true"
          v-bind:class="'gpv_comment_text readonly'"
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "PortalDetail",
  props: ["survey_response_item"],
  data() {
    return {};
  },
  components: {},
  watch: {},
  computed: {},
  methods: {}
};
</script>

<style scoped></style>
