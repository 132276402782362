<template>
  <span class="ml-1" v-if="isKPIthisQuestion && foundObjectiveValue !== null">
    Obj : {{ foundObjectiveValue }}
  </span>
</template>

<script>
export default {
  props: ["isKPIthisQuestion", "foundObjectiveValue"]
};
</script>

<style></style>
