<template>
  <div>
    <modal name="modal-promolocal" :width="`90%`" :height="`auto`" scrollable>
      <v-form
        ref="formAddPromoLocal"
        lazy-validation
        v-model="validFormPromoLocal"
        v-if="selectedAddActionItem"
      >
        <v-card>
          <v-card-title>Promo Local</v-card-title>
          <v-divider class="mx-4 my-0"></v-divider>
          <v-card-text class="text--primary">
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-menu
                  ref="date_menu_start"
                  v-model="date_menu_start"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selectedAddActionItem.startDate"
                      v-bind:label="$t('start_date')"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selectedAddActionItem.startDate"
                    no-title
                    @input="date_menu_start = false"
                    :first-day-of-week="1"
                    :locale="this.$i18n.locale"
                    :reactive="reactive_start"
                    @change="onChangeDates"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-menu
                  ref="date_menu_end"
                  v-model="date_menu_end"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selectedAddActionItem.endDate"
                      v-bind:label="$t('end_date')"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selectedAddActionItem.endDate"
                    no-title
                    @input="date_menu_end = false"
                    :first-day-of-week="1"
                    :locale="this.$i18n.locale"
                    :reactive="reactive_end"
                    @change="onChangeDates"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-autocomplete
                  v-model="selectedAddActionItem.place"
                  v-bind:label="$t('Place')"
                  :items="placeTypes"
                  item-text="label"
                  item-value="value"
                  filled
                  required
                  :rules="requiredRules"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <v-textarea
                  v-model="selectedAddActionItem.comments"
                  v-bind:label="$t('comments')"
                  required
                  :rules="requiredRules"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <v-data-table
                  :headers="addActionItemPromoLocalHeaders"
                  :items="
                    selectedAddActionItem.worksession_survey_action_promo_locals
                  "
                  class="elevation-1"
                  :items-per-page="100"
                  :footer-props="{
                    'items-per-page-options': [10, 50, 100],
                  }"
                  :hide-default-footer="true"
                  disable-pagination
                  :mobile-breakpoint="1100"
                >
                  <template v-slot:item.product="{ item }">
                    {{ `${item.product.name}` }}
                  </template>
                  <template v-slot:item.quantity="{ item }">
                    <v-text-field
                      type="number"
                      dense
                      hide-no-data
                      hide-details
                      v-model="item.quantity"
                      @change="onChangeTableValues('quantity', item.product)"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.freeCharge="{ item }">
                    <v-text-field
                      type="number"
                      dense
                      hide-no-data
                      hide-details
                      v-model="item.freeCharge"
                      @change="onChangeTableValues('freeCharge', item.product)"
                    ></v-text-field>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="mx-4 my-0"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="hideModal">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="onClickSave">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";

export default {
  name: "DialogPromoLocal",
  props: ["placeTypeList"],
  components: {},
  data: function() {
    return {
      date_menu_start: false,
      date_menu_end: false,
      reactive_start: false,
      reactive_end: false,

      selectedAddActionItem: null,

      /********** form-validation options **********/
      validFormPromoLocal: true,
      requiredRules: [(v) => !!v || "Required"],
      placeTypes: [],
    };
  },
  watch: {},
  computed: {
    addActionItemPromoLocalHeaders() {
      let columns = [
        {
          text: `${this.$t("Product")}`,
          value: "product",
          // width: 200,
        },
        {
          text: `${this.$t("Quantity")}`,
          value: "quantity",
          // width: 150,
        },
        {
          text: `${this.$t("Free Charge")}`,
          value: "freeCharge",
          // width: 150,
        },
      ];
      return columns;
    },
  },
  methods: {
    showModal(selectedAddActionItem) {
      this.init(selectedAddActionItem);
      this.$modal.show("modal-promolocal");
    },
    hideModal() {
      this.$modal.hide("modal-promolocal");
    },
    async onChangeDates() {
      if (
        this.selectedAddActionItem.startDate >
        this.selectedAddActionItem.endDate
      ) {
        this.selectedAddActionItem.endDate = this.selectedAddActionItem.startDate;
      }
    },
    onChangeTableValues(changeKey, product) {},
    onClickSave() {
      if (this.$refs.formAddPromoLocal.validate()) {
        this.$emit("onChangeAddActionItem", this.selectedAddActionItem);
      }
    },

    async init(selectedAddActionItem) {
      this.placeTypes = [];
      for (const [key, value] of Object.entries(this.placeTypeList)) {
        let pushItem = { label: value, value: key };
        this.placeTypes.push(pushItem);
      }
      this.selectedAddActionItem = selectedAddActionItem;
    },
  },
  async updated() {},
  async mounted() {},
};
</script>
<style></style>
