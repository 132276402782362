<template>
  <div
    class="web-camera-container"
    :style="`width: ${wrapperWidth ? wrapperWidth + 'px' : '100%'}`"
  >
    <input
      ref="uploader_phototaken"
      class="d-none"
      type="file"
      accept="image/png"
    />
    <div class="camera-button">
      <v-icon v-if="!isCameraOpen" x-large class="mr-2" @click="toggleCamera">
        mdi-camera-marker
      </v-icon>
      <v-btn
        color="red"
        dark
        class="mx-1 my-2"
        @click="toggleCamera"
        v-if="isCameraOpen"
      >
        <span>Close Camera</span>
      </v-btn>
    </div>

    <div v-show="isCameraOpen && isLoading" class="camera-loading">
      <ul class="loader-circle">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>

    <div
      v-if="isCameraOpen"
      v-show="!isLoading"
      class="camera-box"
      :class="{ flash: isShotPhoto }"
    >
      <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

      <video v-show="!isPhotoTaken" ref="camera" autoplay></video>

      <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas"></canvas>
    </div>

    <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
      <button type="button" class="button" @click="takePhoto">
        <img
          src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"
        />
      </button>
    </div>

    <div v-if="isPhotoTaken && isCameraOpen" class="camera-download">
      <a
        id="downloadPhoto"
        download="my-photo.jpg"
        class="button"
        role="button"
        @click="downloadImage"
      >
        <b-icon icon="download" aria-hidden="true"></b-icon>
        Download
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "webCamera",
  props: ["detectedLocation", "wrapperWidth"],
  data: function() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      currentPosition: null
    };
  },
  watch: {},
  computed: {},
  methods: {
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
          setTimeout(() => {
            var videotag_width = this.$refs.camera.videoWidth;
            var videotag_height = this.$refs.camera.videoHeight;
            let rate = videotag_height / videotag_width;
            console.log(
              "videotag_width, videotag_height - ",
              videotag_width,
              videotag_height,
              rate
            );

            videotag_width = this.$refs.camera.offsetWidth;
            videotag_height = videotag_width * rate;
            console.log(
              "videotag_width, videotag_height - ",
              videotag_width,
              videotag_height
            );

            this.$refs.camera.width = videotag_width;
            this.$refs.camera.height = videotag_height;

            this.$refs.canvas.width = videotag_width;
            this.$refs.canvas.height = videotag_height;
          }, 500);
        })
        .catch(error => {
          this.isLoading = false;
          alert("May the browser didn't support or there is some errors.");
        });

      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          this.currentPosition = { latitude, longitude };
          console.log(this.currentPosition);
        },
        error => {
          console.log(error.message);
          this.currentPosition = null;
          this.checkDistance();
        }
      );
    },

    stopCameraStream() {
      if (this.$refs.camera.srcObject) {
        let tracks = this.$refs.camera.srcObject.getTracks();
        tracks.forEach(track => {
          track.stop();
        });
      }
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
          if (this.detectedLocation) this.detectedLocation();
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext("2d");
      var canvas_width = this.$refs.canvas.width;
      var canvas_height = this.$refs.canvas.height;
      console.log(
        "canvas_width, canvas_height - ",
        canvas_width,
        canvas_height
      );
      context.drawImage(this.$refs.camera, 0, 0, canvas_width, canvas_height);
    },

    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document
        .getElementById("photoTaken")
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    },

    async getTakenPhotoFile() {
      console.log(this.$refs.uploader_phototaken.files);
      let photoInstance = document.getElementById("photoTaken");
      if (photoInstance && this.isPhotoTaken) {
        const blob = await new Promise(resolve =>
          photoInstance.toBlob(resolve, "image/png")
        );
        const file = new File([blob], "mycanvas.png", { type: "image/png" });
        const dT = new DataTransfer();
        dT.items.add(file);
        this.$refs.uploader_phototaken.files = dT.files;
        console.log(this.$refs.uploader_phototaken.files);
        if (this.$refs.uploader_phototaken.files.length > 0) {
          return this.$refs.uploader_phototaken.files[0];
        }
      }
      return null;
    },

    getCurrentPosition() {
      return this.currentPosition;
    },

    init() {}
  },
  async updated() {},
  async mounted() {}
};
</script>

<style>
.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.web-camera-container .camera-button {
  margin-bottom: 0rem;
}

.web-camera-container .camera-box.flash {
  opacity: 1;
}
.web-camera-container .camera-box .camera-shutter {
  opacity: 0;
  background-color: #fff;
  position: absolute;
}

.web-camera-container .camera-shoot {
  margin: 1rem 0;
}

.web-camera-container .camera-shoot button {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.web-camera-container .camera-shoot button img {
  height: 35px;
  object-fit: cover;
}

.camera-loading {
  overflow: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
  min-height: 150px;
  margin: 3rem 0 0 -1.2rem;
}

.camera-loading ul {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 999999;
  margin: 0;
}

.camera-loading .loader-circle {
  display: block;
  height: 14px;
  margin: 0 auto;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  position: absolute;
  width: 100%;
  padding: 0;
}

.camera-loading .loader-circle li {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  padding: 0;
  position: relative;
  margin: 0 0 0 4px;
  background: #999;
  animation: preload 1s infinite;
  top: -50%;
  border-radius: 100%;
}

.camera-loading .loader-circle li:nth-child(2) {
  animation-delay: 0.2s;
}

.camera-loading .loader-circle li:nth-child(3) {
  animation-delay: 0.4s;
}

.camera-box video,
.camera-box canvas {
  width: 100%;
  height: auto;
  /* aspect-ratio: 1/1; */
  background: burlywood;
}

@keyframes preload {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
</style>
